import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ActionContext from "../../context/ActionContext";
import DeleteModal from "../../fragments/DeleteModal";
import { logoutUser } from "../../redux/auth";
import { clearCache } from "../../helper";

interface MyComponentProps {
  visible?: boolean;
  onCancel?: () => void;
}

const ConfirmLogoutModal: React.FC<MyComponentProps> = ({
  visible,
  onCancel,
}) => {
  const location = useLocation();
  const urlSave = location.pathname.split("/")[1];
  const [logoutload, setLogOutLoad] = useState(false);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    setLogOutLoad(true);
    const data = await dispatch(logoutUser({} as any) as any);
    // console.log(data);

    if (data?.payload?.data?.message === "logout successful" && data?.payload?.status === "success") {
      actionCtx?.setLogoutModal(false);
      setLogOutLoad(false);
      clearCache();
      localStorage.clear();
      // navigate("/login");
      window.location.replace(`/login?${urlSave}`);
    }
    else{
        setLogOutLoad(false);
    }
  };

  return (
    <DeleteModal
      loading={logoutload}
      onDelete={handleLogout}
      visible={actionCtx?.logoutModal}
      onCancel={() => {
        actionCtx?.setLogoutModal(false);
      }}
      title={`Want to Logout ?`}
      deleteText={`Yes, logout`}
       buttonColor="error-light"
      text={`Are you sure you want to log out? Please confirm by clicking "Yes, logout" or "Cancel".`}
      btnClassName={`logout-btn-classmaen`}
    />
  );
};

export default ConfirmLogoutModal;
