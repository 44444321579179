import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitleTextBox from "../../../fragments/TitleTextBox";
import ContainerWrapper from "../../../fragments/ContainerWrapper";
import "../../../styles/dashboard/insights/InsightIndex.css";
import ProgressBar from "../../../fragments/ProgressBar";
import InsightBarChart from "./charts/InsightBarChart";
import ActionContext from "../../../context/ActionContext";
import smallTerminalImg from "../../../assets/small-bankbox-terminal-img.svg";
import { useNavigate } from "react-router-dom";
import { RouteLinks } from "../../../routes/RouteLinks";
import { useDispatch, useSelector } from "react-redux";
import { getAllInsights } from "../../../redux/insight";
import RootState from "../../../redux/types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  formatNumWithCommaNairaSymbol,
  lowerCaseWrap,
  reactSelectStyle,
  trimLongString,
} from "../../../helper";
import {
  // formatDate,
  getPercentageVal,
  sumAllNum,
} from "../../../utils/helpers";
import { RavenInputField, RavenToolTip } from "@ravenpay/raven-bank-ui";
import { encrypt2 } from "../../../utils/encrypt";
import CustomDateSelectModal from "../../../fragments/CustomDateSelectModal";
import SpecificDateSelectModal from "../../../fragments/SpecificDateSelectModal";

const InsightIndex = () => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, insights } = useSelector(
    (state: RootState) => state.insights
  );

  type showModalProp = {
    view: boolean;
    export?: boolean;
    selectSpecificDate?: boolean;
    customDate: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    view: false,
    export: false,
    selectSpecificDate: false,
    customDate: false,
  });
  const [chartLine, setChartline] = useState(true);
  const [chartData, setchartData] = useState<[]>([]);
  useEffect(() => {
    const theme = localStorage?.getItem("theme");

    if (theme === "light") {
      setChartline(true);
    }
    if (theme === "dark") {
      setChartline(false);
    }
  }, [actionCtx?.themeChange]);
  // const date
  const [durationVal, setDurationVal] = useState<any>({
    label: "All time",
    value: " ",
  });
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const getDatesInRange = (daysAgo: number) => {
    var dates = [] as any;
    var currentDate = new Date();
    for (var i = 0; i < daysAgo; i++) {
      var date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      dates.push(date.toISOString().slice(0, 10)); // Format date as "YYYY-MM-DD"
    }
    const obj = {
      start: dates[0],
      end: dates[dates?.length - 1],
    };

    return obj;
  };
  const getStartAndEndDate = () => {
    let val;
    if (durationVal?.label === "All time") {
      const obj = {
        startDate: "",
        stopDate: "",
      };
      val = obj;
    }
    if (durationVal?.label === "Today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // Months are zero-based
      const day = today.getDate();

      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      const obj = {
        startDate: formattedDate,
        stopDate: formattedDate,
      };
      val = obj;
    }
    if (durationVal?.label === "Yesterday") {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // Months are zero-based
      const day = today.getDate();

      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + (Number(day) - 1) : Number(day) - 1
      }`;
      const obj = {
        startDate: formattedDate,
        stopDate: formattedDate,
      };
      val = obj;
    }
    if (
      durationVal?.label === "Last 30 Days" ||
      durationVal?.label === "Last 7 Days" ||
      durationVal?.label === "Last 14 Days"
    ) {
      // getDatesInRange(7);
      const obj = {
        stopDate: getDatesInRange(
          durationVal?.label === "Last 7 Days"
            ? 7
            : durationVal?.label === "Last 14 Days"
            ? 14
            : 30
        )?.start,
        startDate: getDatesInRange(
          durationVal?.label === "Last 7 Days"
            ? 7
            : durationVal?.label === "Last 14 Days"
            ? 14
            : 30
        )?.end,
      };
      val = obj;
    }
    if (
      durationVal?.label === "Specific Day" ||
      durationVal?.label === "Custom"
    ) {
      const obj = {
        startDate: dateRange?.startDate?.replaceAll("/", "-"),
        stopDate: dateRange?.endDate?.replaceAll("/", "-"),
      };
      val = obj;
    }

    return val;
  };

  useEffect(() => {
    if (Object?.keys(actionCtx?.configDetails)?.length > 0) {
      if (durationVal.label === "Specific Day" && !dateRange.startDate) {
        // console.log("---test----");
        return;
      }
      if (durationVal.label === "Custom" && !dateRange.startDate) {
        // console.log("---test----");
        return;
      }
      getAllInsightsFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.configDetails, durationVal, dateRange]);

  // useEffect(() => {
  //   if (Object?.keys(actionCtx?.configDetails)?.length > 0) {
  //     getAllInsightsTerminalsFunc();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [actionCtx?.configDetails]);
  const [noTableContent, setNoTableContent] = useState(true);
  const getAllInsightsFunc = async () => {
    const obj = {
      startDate: getStartAndEndDate()?.startDate,
      endDate: getStartAndEndDate()?.stopDate,
    };
    // console.log(obj);

    const data = await dispatch(getAllInsights(obj as any) as any);
    if (
      durationVal?.label === "All time" &&
      data?.payload?.status === "success"
    ) {
      const lengthVal = data?.payload?.data?.data?.terminals;
      if (lengthVal?.length > 0) {
        setNoTableContent(false);
      } else {
        setNoTableContent(true);
      }
    }
  };

  // const getAllInsightsTerminalsFunc = async () => {
  //   dispatch(getAllInsightTerminals({} as any) as any);
  // };

  const formatChatData = (param: any) => {
    if (param?.length > 0) {
      const newList = param?.map((chi: any) => chi?.volume);
      // console.log(newList);

      return newList;
    }
  };

  useEffect(() => {
    if (insights?.chartData?.length > 0) {
      setchartData(formatChatData(insights?.chartData));
    }
    // console.log(insights);
  }, [insights]);

  const calendarIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M21 10.5H3M16 2.5V6.5M8 2.5V6.5M7.8 22.5H16.2C17.8802 22.5 18.7202 22.5 19.362 22.173C19.9265 21.8854 20.3854 21.4265 20.673 20.862C21 20.2202 21 19.3802 21 17.7V9.3C21 7.61984 21 6.77976 20.673 6.13803C20.3854 5.57354 19.9265 5.1146 19.362 4.82698C18.7202 4.5 17.8802 4.5 16.2 4.5H7.8C6.11984 4.5 5.27976 4.5 4.63803 4.82698C4.07354 5.1146 3.6146 5.57354 3.32698 6.13803C3 6.77976 3 7.61984 3 9.3V17.7C3 19.3802 3 20.2202 3.32698 20.862C3.6146 21.4265 4.07354 21.8854 4.63803 22.173C5.27976 22.5 6.11984 22.5 7.8 22.5Z"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const filteroptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 Days", value: "1_week" },
    { label: "Last 30 Days", value: "4_weeks" },
    { label: "Specific Day", value: "specific" },
    { label: "Custom", value: "custom" },
    {
      label: "All time",
      value: " ",
    },
  ];
  const [selectOpen, setSelectOpen] = useState(false);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitleTextBox
          text="Manage Bankbox devices on the platform."
          title="Insights"
        >
          {!noTableContent && (
            <div className="filter-by-duration-box card-bg">
              <RavenInputField
                selectOption={filteroptions}
                type="select"
                placeholder=" "
                value={durationVal}
                onChange={(e: any) => {
                  if (e.label === "Specific Day") {
                    setShowModal((prev) => {
                      return { ...prev, selectSpecificDate: true };
                    });
                    // return
                  }
                  if (e.label === "Custom") {
                    setShowModal((prev) => {
                      return { ...prev, customDate: true };
                    });
                    // return
                  }
                  setDurationVal(e);
                }}
                // selectMenuOpen={selectOpen}
                selectStyles={reactSelectStyle}
              />
              {/* <p className="text">All Time</p> */}
              <div
                onClick={() => {
                  setSelectOpen(!selectOpen);
                }}
                className="img-wrap-filter"
              >
                <figure className="img-box">{calendarIcon}</figure>
              </div>
            </div>
          )}
        </TitleTextBox>
        {/* title box end */}
        {/* container wrapper start */}
        <ContainerWrapper
          //   loading={true}
          noContent={
            Object?.keys(insights)?.length < 1 ||
            insights?.terminals?.length < 1
              ? true
              : false
          }
          noContentLoading={loading}
          noContentText={`You do not have any insight yet, once you do \n it would show up here.`}
          width={`100%`}
          dontShowHeader
        >
          {/* insight index wrap box start */}
          <div
            // onClick={() => {
            //   console.log(insights);
            // }}
            className="insights-index-wrap-box"
          >
            {/* top insight info box start */}
            <div className="top-insight-info-box border-theme-bottom">
              {/* left box insight box start */}
              <div className="left-box-insight-box">
                <div className="content-insight-wrap border-theme">
                  {/* top content start */}
                  <div className="top-content border-theme-bottom">
                    <p className="title-label grey-white-color">
                      Overall Transaction Volume
                    </p>
                    {loading ? (
                      <Skeleton width={200} height={32} />
                    ) : (
                      <p className="amount-value">
                        {formatNumWithCommaNairaSymbol(
                          sumAllNum([
                            insights?.overallSum?.sumPdonCard,
                            insights?.overallSum?.sumTransfer,
                          ])
                        )}
                      </p>
                    )}
                  </div>
                  {/* top content end */}
                  {/* bottom content start */}
                  <div className="bottom-content">
                    {/* content progress box wrap start */}
                    <div className="content-progress-box">
                      <p className="title grey-white-color">
                        Card Transactions
                      </p>
                      <div className="progress-amount-box">
                        {loading ? (
                          <>
                            {" "}
                            <Skeleton width={300} height={15} />
                            <Skeleton width={50} height={15} />
                          </>
                        ) : (
                          <>
                            {" "}
                            <ProgressBar
                              bgcolor="linear-gradient(to bottom, #ffa554, #e56f04)"
                              completed={
                                getPercentageVal(
                                  insights?.overallSum?.sumPdonCard,
                                  insights?.overallSum?.sumTransfer
                                ) || (0 as any)
                              }
                              heightVal={17}
                            />
                            <p className="amount grey-white-color">
                              {formatNumWithCommaNairaSymbol(
                                insights?.overallSum?.sumPdonCard
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    {/* content progress box wrap end */}
                    {/* content progress box wrap start */}
                    <div className="content-progress-box">
                      <p className="title grey-white-color">Bank Transfers</p>
                      <div className="progress-amount-box">
                        {loading ? (
                          <>
                            {" "}
                            <Skeleton width={300} height={15} />
                            <Skeleton width={50} height={15} />
                          </>
                        ) : (
                          <>
                            {" "}
                            <ProgressBar
                              bgcolor="linear-gradient(to bottom, #014345 ,
                            #0B8376)"
                              completed={
                                getPercentageVal(
                                  insights?.overallSum?.sumTransfer,
                                  insights?.overallSum?.sumPdonCard
                                ) || (0 as any)
                              }
                              heightVal={17}
                            />
                            <p className="amount grey-white-color">
                              {formatNumWithCommaNairaSymbol(
                                insights?.overallSum?.sumTransfer
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    {/* content progress box wrap end */}
                  </div>
                  {/* bottom content end */}
                </div>
              </div>
              {/* left box insight box end */}
              {/* right box insight box start */}
              <div className="right-box-insight-box">
                <div className="content-insight-wrap border-theme">
                  <div className="title-box">
                    <p className="title">Transactions per month</p>
                  </div>
                  {/* chart box wrap start */}
                  <div
                    className={`chart-content-box-wrap ${
                      chartLine && "chart-content-box-wrap-light"
                    }`}
                  >
                    <div className="wrapper">
                      {" "}
                      <InsightBarChart labelVal={""} chartData={chartData} />
                    </div>
                  </div>
                  {/* chart box wrap end */}
                </div>
              </div>
              {/* right box insight box end */}
            </div>
            {/* top insight info box end */}
            {/*  bottom insight info box start */}
            <div className=" bottom-insight-info-box">
              <div className="title-route-page-box">
                {" "}
                <p className="title">Terminals</p>
                {!noTableContent && insights?.terminals?.length > 9 && (
                  <div
                    onClick={() => {
                      navigate(RouteLinks?.Terminals);
                    }}
                    className="view-all-box-terminal"
                  >
                    <p className="view-all">View All Terminals</p>
                    <div className="icon-box">
                      <figure className="img-box">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="img"
                        >
                          <path
                            className="dull-bright-filter"
                            d="M3 9H15M15 9L10.5 4.5M15 9L10.5 13.5"
                            stroke="#002856"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </figure>
                    </div>
                  </div>
                )}
              </div>

              {/* all terminal boxes wrap start */}
              <div
                style={{
                  justifyContent:
                    insights?.terminals?.length < 4
                      ? "flex-start"
                      : "space-between",
                  display:
                    insights?.terminals?.length < 4
                      ? "flex"
                      : "grid",
                }}
                className="all-terminals-boxes-wrap"
              >
                {insights?.terminals?.map((chi: any, idx: any) => {
                  const { tid_label, serial, volume } = chi || {};
                  return (
                    <div
                      key={idx}
                      className="terminal-box-wrap card-bg border-theme"
                      onClick={async () => {
                        const encryptedSerial = await encrypt2(serial ?? "");
                        localStorage?.setItem("takwoski", encryptedSerial);
                        navigate(RouteLinks?.Terminal_details);
                      }}
                      style={{
                        minWidth:
                          "32rem",
                      }}
                    >
                      {/* top serial  box start */}
                      <div className="top-serial-box">
                        <div className="img-terminal">
                          {loading ? (
                            <Skeleton width={35} height={35} circle />
                          ) : (
                            <figure className="img-box">
                              <img
                                src={smallTerminalImg}
                                alt=""
                                className="img"
                              />
                            </figure>
                          )}
                        </div>
                        <div className="terminal-serial-box">
                          {loading ? (
                            <Skeleton
                              width={70}
                              height={17}
                              style={{ marginBottom: ".3rem" }}
                            />
                          ) : (
                            <p className="terminal">
                              {lowerCaseWrap(tid_label) || "---"}
                            </p>
                          )}
                          {loading ? (
                            <Skeleton width={200} height={11} />
                          ) : (
                            <div className="serial grey-white-color tooltip-hover-wrap">
                              Serial •{" "}
                              <span>
                                {serial ? trimLongString(serial, 15) : "---"}
                              </span>
                              {serial?.length > 14 && (
                                <RavenToolTip
                                  color="black-light"
                                  position={`bottom-right`}
                                  text={serial}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* top serial  box end */}
                      {/* bottom box start */}
                      <div className="bottom-value-box grey-bg">
                        {loading ? (
                          <Skeleton width={100} height={22} />
                        ) : (
                          <p className="amount">
                            {formatNumWithCommaNairaSymbol(volume) || "0.00"}
                          </p>
                        )}
                      </div>
                      {/* bottom box end */}
                    </div>
                  );
                })}
              </div>
              {/* all terminal boxes wrap end */}
            </div>
            {/*  bottom insight info box end */}
          </div>
          {/* insight index wrap box end */}
        </ContainerWrapper>
        {/* container wrapper end */}
      </DashboardLayout>
      {/* specific date start  */}
      <SpecificDateSelectModal
        visible={showModal?.selectSpecificDate}
        onFinish={(e: any) => {
          // console.log(e);
          setDateRange((prev: any) => {
            return {
              ...prev,
              startDate: e,
              endDate: e,
            };
          });

          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
          setTimeout(() => {
            setDateRange((prev: any) => {
              return {
                ...prev,
                startDate: "",
                endDate: "",
              };
            });
          }, 1000);
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
      />
      {/* specific date end */}
      {/* Custom date start */}
      <CustomDateSelectModal
        visible={showModal?.customDate}
        onFinish={(e) => {
          // formatFilterDate(e);
          // console.log(e);

          setDateRange((prev: any) => {
            return {
              ...prev,
              startDate: e?.split("_")[0],
              endDate: e?.split("_")[1],
            };
          });
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
          setTimeout(() => {
            setDateRange((prev: any) => {
              return {
                ...prev,
                startDate: "",
                endDate: "",
              };
            });
          }, 1000);
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
      />
      {/* custom date end */}
    </>
  );
};

export default InsightIndex;
