import InsightIndex from "../../pages/private/insight/InsightIndex";
import TerminalDetailsInsight from "../../pages/private/insight/TerminalDetailsInsight";
import OverviewIndex from "../../pages/private/overview/OverviewIndex";
import AllTerminals from "../../pages/private/terminals/AllTerminals";
import TransactionIndex from "../../pages/private/transaction/TransactionIndex";
import { RouteLinks } from "../RouteLinks";

export const private_routes_group = [
  {
    path: RouteLinks?.Overview,
    element: OverviewIndex,
  },
  {
    path: RouteLinks?.Transactions,
    element: TransactionIndex,
  },
  {
    path: RouteLinks?.Terminals,
    element: AllTerminals,
  },
  {
    path: RouteLinks?.Insights,
    element: InsightIndex,
  },
  {
    path: RouteLinks?.Terminal_details,
    element: TerminalDetailsInsight,
  },
];
