import axios from "./axios";
// import CryptoJS from "crypto-js";
import { decrypt2 } from "./encrypt";
// import { ENCRYPTION_KEY_VAL } from "./helpers";

const setAuthToken = () => {
  const token = localStorage.getItem("shambala");    

  const decryptedVal = decrypt2(token ?? "");
  // console.log(decryptedVal, "----------token------------");
  if (token) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${decryptedVal?.token}`,
    };
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
