/* eslint-disable no-lone-blocks */
import React, { useContext, useState } from "react";
import "../styles/fragments/FilterFragment.css";
import useWindowSize from "../utils/UseWindowSize";
// import { useOnClickOutsideTwo } from "../utils/useOutsideClickTwo";
import {
  RavenButton,
  RavenCheckBox,
  RavenInputField,
} from "@ravenpay/raven-bank-ui";
import { icons } from "../assets/icons";
import { formatDate } from "../utils/helpers";
import {
  getInsightTerminalTransactions,
  getInsightTerminalTransactionsFilter,
  getSingleInsightTerminalTransactionsFilter,
} from "../redux/insight";
import { useDispatch } from "react-redux";
import { decrypt2 } from "../utils/encrypt";
import ActionContext from "../context/ActionContext";
// import { useOnClickOutsideTwo } from "src/utils/useOutsideClickTwo";
// import useWindowSize from "src/utils/UseWindowSize";

interface MyComponentProps {
  text?: string;
  // onClick?: () => void;
  children?: React.ReactNode;
  defaultFilterBy?: any;
  page?: string;
  typeList?: any[];
  filters?: {
    label: string;
    filterBy?: string;
    options: {
      value: string;
      label:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | null
        | undefined;
    }[];
  }[];
}

const downloadIcon = (
  <svg
    className="img"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      d="M6 12H18M3 6H21M9 18H15"
      stroke="#676767"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FilterFragment: React.FC<MyComponentProps> = ({
  text,
  // onClick,
  children,
  defaultFilterBy,
  page,
  typeList,
  filters,
}) => {
  const [showDrop, setShowDrop] = useState(false);
  const size = useWindowSize();
  const dispatch = useDispatch();
  // const testingFilterFragRef = useOnClickOutsideTwo(() => {
  //   setShowDrop(false);
  // });
  const actionCtx = useContext(ActionContext);
  function addDays(date: Date | string, days: number) {
    // Function to add Days
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  }
  const [activeFilter, setActiveFilter] = useState<{
    type: { label: string; value: string }[];
    region?: string;
    days: string;
  }>({ type: [], days: "" });
  const [activeTab, setActiveTab] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showFilter, setShowFilter] = useState(false);
  // const [q, setQ] = useState("");
  const [stateChange, setStatChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
    filterValue: string | undefined;
    filterBy?: string | undefined;
  }>({
    startDate: "",
    endDate: formatDate(addDays(new Date(), 1)),
    filterValue: undefined,
    filterBy: defaultFilterBy || "type",
  });

  function isFilter(s: string) {
    if (activeTab === "date" && s === activeFilter.days) {
      return true;
    } else
      return (
        activeFilter.type &&
        activeFilter.type.some((item: { value: string }) => item.value === s)
      );
  }

  const [dateFilter, setDateFilter] = useState<{
    to: Date | any;
    from: Date | any;
  }>({
    from: "",
    to: formatDate(addDays(new Date(), 1)),
  });

  const [statusFilter, setStatusFilter] = useState<any>("");
  function setFilter(e: string) {
    setStatChange(!stateChange);
    if (activeTab === "date") {
      setActiveFilter({
        ...activeFilter,
        days: e,
      });
    } else {
      // Parse the value as an object
      const parsedValue = JSON.parse(e);

      // Check if the value is already in the type array
      const isValueInTypeArray = activeFilter.type.some(
        (item) => item.value === parsedValue.value
      );

      if (isValueInTypeArray) {
        // If the value is in the array, remove it
        setActiveFilter({
          ...activeFilter,
          type: activeFilter.type.filter(
            (item) => item.value !== parsedValue.value
          ),
        });
      } else {
        // If the value is not in the array, add it
        setActiveFilter({
          ...activeFilter,
          type: [...activeFilter.type, parsedValue],
        });
      }
    }
  }

  const getDatesInRange = (daysAgo: number) => {
    var dates = [] as any;
    var currentDate = new Date();
    for (var i = 0; i < daysAgo; i++) {
      var date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      dates.push(date.toISOString().slice(0, 10)); // Format date as "YYYY-MM-DD"
    }
    const obj = {
      start: dates[0],
      end: dates[dates?.length - 1],
    };

    return obj;
  };

  const [dateVal, setDateVal] = useState<any>("All");
  const getStartAndEndDate = () => {
    let val;
    if (dateVal === "Today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // Months are zero-based
      const day = today.getDate();

      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      const obj = {
        startDate: formattedDate,
        stopDate: formattedDate,
      };
      val = obj;
    }
    if (
      dateVal === "Last 30 Days" ||
      dateVal === "Last 7 Days" ||
      dateVal === "Last 14 Days"
    ) {
      // getDatesInRange(7);
      const obj = {
        startDate: getDatesInRange(
          dateVal === "Last 7 Days" ? 7 : dateVal === "Last 14 Days" ? 14 : 30
        )?.start,
        stopDate: getDatesInRange(
          dateVal === "Last 7 Days" ? 7 : dateVal === "Last 14 Days" ? 14 : 30
        )?.end,
      };
      val = obj;
    }
    if (dateVal === "Specific Day" || dateVal === "Custom") {
      const obj = {
        stopDate: dateRange?.startDate?.replaceAll("/", "-"),
        startDate: dateRange?.endDate?.replaceAll("/", "-"),
      };
      val = obj;
    }

    return val;
  };

  //get the last 7th Day
  function getNthDaysAgo(d: number) {
    const today = new Date(); // Get today's date
    const nthDay = new Date(today); // Create a new date object

    nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

    return nthDay;
  }

  function handleFilter(str?: string) {
    const seventhDayAgo = getNthDaysAgo(7);
    const last14Days = getNthDaysAgo(14);
    const last30Days = getNthDaysAgo(30);

    const formated30days = formatDate(last30Days);
    const formated14days = formatDate(last14Days);
    const formated7days = formatDate(seventhDayAgo);

    setDateRange({
      ...activeFilter,
      ...dateRange,
      startDate:
        str === "Today"
          ? formatDate(new Date())
          : str === "Last 7 Days"
          ? formated7days
          : str === "Last 14 Days"
          ? formated14days
          : str === "Last 30 Days"
          ? formated30days
          : dateFilter.from,
      endDate:
        str === "Today"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 7 Days"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 14 Days"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 30 Days"
          ? formatDate(addDays(new Date(), 1))
          : dateFilter.to,
      // filterValue: undefined,
    });
  }

  async function fetchAll() {
    setLoading(true);
    let resp: any;
    if (page === "all-transactions") {
      const obj = {
        perPage: actionCtx?.perPageGlobalVal?.value,
        currentPage: 1,
      };
      resp = await dispatch(getInsightTerminalTransactions(obj as any) as any);
    }

    if (resp?.payload?.status === "success") {
      setLoading(false);
      setShowFilter(false);
    } else {
      setLoading(false);
    }
  }

  function formatQuery(filters: any) {
    let p: string = "";
    // eslint-disable-next-line array-callback-return
    activeFilter?.type.map((d: any, i: number) => {
      if (activeFilter.type.length !== i + 1) {
        p = p + d.value + ",";
      } else {
        p = p + d.value;
      }
    });

    return p;
  }

  async function reqFilter() {
    let resp: any;
    setLoading(true);
    if (activeFilter.days === "All" && activeFilter.type.length === 0) {
      fetchAll();
    }

    if (activeFilter.days.length > 0 || activeFilter.type.length > 0) {
      if (page === "all-transactions") {
        const obj = {
          ...dateRange,
          // start_date: (dateRange.startDate)?.replaceAll("/", "-"),
          // stop_date: (dateRange.endDate)?.replaceAll("/", "-"),
          start_date: getStartAndEndDate()?.startDate,
          stop_date: getStartAndEndDate()?.stopDate,
          region: formatQuery(activeFilter?.region),
          status: statusFilter?.value === "reset" ? "" : statusFilter?.value,
        };
        // console.log(obj);

        resp = await dispatch(
          getInsightTerminalTransactionsFilter(obj as any) as any
        );
      }
      if (page === "all-single-transactions") {
        const serialVal = localStorage?.getItem("takwoski");
        const decryptedVal = decrypt2(serialVal ?? "");
        const obj = {
          ...dateRange,
          serial: decryptedVal,
          // start_date: (dateRange.startDate)?.replaceAll("/", "-"),
          // stop_date: (dateRange.endDate)?.replaceAll("/", "-"),
          start_date: getStartAndEndDate()?.startDate,
          stop_date: getStartAndEndDate()?.stopDate,
          region: formatQuery(activeFilter?.region),
          status: statusFilter?.value === "reset" ? "" : statusFilter?.value,
        };
        // console.log(obj);

        resp = await dispatch(
          getSingleInsightTerminalTransactionsFilter(obj as any) as any
        );
      }
      if (resp?.payload?.status === "success") {
        setLoading(false);
        setShowFilter(false);
        setShowDrop(false);
      } else {
        setLoading(false);
      }
    }
  }

  return (
    <div
      //   onClick={() => {
      //     setShowDrop(true);
      //   }}
      // ref={testingFilterFragRef}
      className="filter-fragment-index-wrap-outer"
    >
      {" "}
      <div
        onClick={() => {
          setShowDrop(!showDrop);
        }}
        className="filter-fragment-index-wrap grey-bg"
      >
        {(size.width as any) > 900 && (
          <p className="text">{text || "Filter"}</p>
        )}
        <figure className="img-box">{downloadIcon}</figure>
      </div>
      {/* children drop start */}
      {showDrop && (
        <div className="children-drop-box card-bg">
          {children ? (
            children
          ) : (
            <>
              {" "}
              <div
                className={`filter-export__drop-down filter-export__drop-down--open `}
              >
                <div className="filter-export__drop-down-wrap">
                  <div className="dropdown__header">
                    <p>Filter</p>
                    <p
                      onClick={() => {
                        {
                          setShowFilter(false);
                          setActiveFilter({
                            days: "",
                            type: [],
                          });
                          fetchAll();
                          setDateVal("")
                          setActiveTab("")
                          setStatusFilter("")
                        }
                      }}
                    >
                      Clear All
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      activeTab === "date"
                        ? setActiveTab("empty")
                        : setActiveTab("date");
                    }}
                    className="dropdown__date-title"
                  >
                    <p>Date</p>

                    <div
                      onClick={() => {
                        activeTab === "type"
                          ? setActiveTab("type")
                          : activeTab === "date"
                          ? setActiveTab("date")
                          : setActiveTab("empty");
                        setActiveFilter({
                          ...activeFilter,
                          days: "",
                        });
                      }}
                      className={`dropdown__tags ${
                        activeFilter?.days && "show"
                      }`}
                    >
                      {" "}
                      <p>{activeFilter?.days}</p>
                      <figure>{icons.x_circle}</figure>
                    </div>

                    <div
                      className={`tag-close-icon ${
                        activeFilter?.days && "hide"
                      }`}
                    >
                      <figure>{icons.chevron_down}</figure>
                    </div>
                  </div>

                  <div
                    className={`drop-down__item ${
                      activeTab === "date" && "active"
                    }`}
                  >
                    <span
                      onClick={() => {
                        setFilter("All");
                        handleFilter("All");
                        setDateVal("All");
                      }}
                      className={`${isFilter("All") && "active"}`}
                    >
                      {isFilter("All") ? (
                        <figure>{icons.radio_check}</figure>
                      ) : (
                        <figure>{icons.radio_unchecked}</figure>
                      )}
                      <p>All</p>
                    </span>
                    <span
                      onClick={() => {
                        setFilter("Today");
                        handleFilter("Today");
                        setDateVal("Today");
                      }}
                      className={`${isFilter("Today") && "active"}`}
                    >
                      {isFilter("Today") ? (
                        <figure>{icons.radio_check}</figure>
                      ) : (
                        <figure>{icons.radio_unchecked}</figure>
                      )}
                      <p>Today</p>
                    </span>
                    <span
                      onClick={() => {
                        setFilter("Last 7 Days");
                        handleFilter("Last 7 Days");
                        setDateVal("Last 7 Days");
                      }}
                      className={`${isFilter("Last 7 Days") && "active"} `}
                    >
                      {isFilter("Last 7 Days") ? (
                        <figure>{icons.radio_check}</figure>
                      ) : (
                        <figure>{icons.radio_unchecked}</figure>
                      )}
                      <p>Last 7 Days</p>
                    </span>
                    <span
                      onClick={() => {
                        setFilter("Last 30 Days");
                        handleFilter("Last 30 Days");
                        setDateVal("Last 30 Days");
                      }}
                      className={`${isFilter("Last 30 Days") && "active"}`}
                    >
                      {isFilter("Last 30 Days") ? (
                        <figure>{icons.radio_check}</figure>
                      ) : (
                        <figure>{icons.radio_unchecked}</figure>
                      )}
                      <p>Last 30 Days</p>
                    </span>
                    <span
                      onClick={() => {
                        setFilter("Specific Day");
                        handleFilter("Specific Day");
                        setDateVal("Specific Day");
                      }}
                      className={`custom-date ${
                        isFilter("Specific Day") && "active "
                      }`}
                    >
                      <div className="custom-date__select">
                        {isFilter("Specific Day") ? (
                          <figure>{icons.radio_check}</figure>
                        ) : (
                          <figure>{icons.radio_unchecked}</figure>
                        )}
                        <p>Specific Date</p>
                      </div>

                      <div
                        className={`custom-date__form-wrap ${
                          isFilter("Specific Day") && "show"
                        }`}
                      >
                        <RavenInputField
                          color="deep-green-light"
                          label="date"
                          onChange={(e: Date) => {
                            setDateFilter({
                              from: formatDate(new Date(e)),
                              to: formatDate(addDays(new Date(), 1)),
                            });
                            setDateRange({
                              startDate: formatDate(new Date(e)),
                              endDate: formatDate(addDays(new Date(e), 1)),
                              filterValue: undefined,
                            });
                          }}
                          dateOptions={{ maxDate: new Date() }}
                          placeholder="Select a date"
                          type="date"
                        />
                      </div>
                    </span>
                    <span
                      onClick={() => {
                        setFilter("Custom");
                        handleFilter("Custom");
                        setDateVal("Custom");
                      }}
                      className={`custom-date ${
                        isFilter("Custom") && "active "
                      }`}
                    >
                      <div className="custom-date__select">
                        {isFilter("Custom") ? (
                          <figure>{icons.radio_check}</figure>
                        ) : (
                          <figure>{icons.radio_unchecked}</figure>
                        )}
                        <p>Custom</p>
                      </div>

                      <div
                        className={`custom-date__form-wrap ${
                          isFilter("Custom") && "show"
                        }`}
                      >
                        <RavenInputField
                          color="deep-green-light"
                          label="Start Date"
                          placeholder="Select a date"
                          type="date"
                          onChange={(e: Date) => {
                            setDateFilter({
                              ...dateFilter,
                              from: formatDate(new Date(e)),
                            });
                            setDateRange({
                              ...dateRange,
                              startDate: formatDate(new Date(e)),
                            });
                          }}
                          dateOptions={{ maxDate: new Date() }}
                        />
                        <RavenInputField
                          color="deep-green-light"
                          label="End Date"
                          placeholder="Select a date"
                          onChange={(e: Date) => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            setDateFilter({
                              ...dateFilter,
                              to: formatDate(new Date(e)),
                            });
                            setDateRange({
                              ...dateRange,
                              endDate: formatDate(new Date(e)),
                            });
                          }}
                          disabled={!dateRange?.startDate}
                          type="date"
                          dateOptions={{
                            maxDate: new Date(),
                            minDate: dateRange?.startDate,
                          }}
                        />
                      </div>
                    </span>
                  </div>

                  {typeList && (
                    <>
                      <div
                        onClick={() => {
                          activeTab === "type"
                            ? setActiveTab("empty")
                            : setActiveTab("type");
                        }}
                        className="dropdown__date-title"
                      >
                        <p>Type</p>

                        <div
                          onClick={() => {
                            activeTab === "type"
                              ? setActiveTab("type")
                              : activeTab === "date"
                              ? setActiveTab("date")
                              : setActiveTab("empty");
                            setActiveFilter({
                              ...activeFilter,
                              type: [],
                            });
                          }}
                          className={`dropdown__tags ${
                            activeFilter?.type.length > 0 && "show"
                          }`}
                        >
                          {activeFilter.type.length === 1 ? (
                            <p>{activeFilter?.type[0]?.label}</p>
                          ) : (
                            <p>{activeFilter?.type.length + " Selected"}</p>
                          )}

                          <figure>{icons.x_circle}</figure>
                        </div>

                        <div
                          className={`tag-close-icon ${
                            activeFilter?.type.length > 0 && "hide"
                          }`}
                        >
                          <figure>{icons.chevron_down}</figure>
                        </div>
                      </div>

                      {typeList &&
                        typeList.map((chi, idx) => {
                          return (
                            <div
                              className={`drop-down__item ${
                                activeTab === "type" && "active"
                              }`}
                            >
                              <span
                                onClick={() => setFilter(JSON.stringify(chi))}
                                className={`${isFilter(chi.value) && "active"}`}
                              >
                                <RavenCheckBox
                                  onChange={(e) => {
                                    handleFilter(chi.label);
                                    setFilter(JSON.stringify(chi));
                                  }}
                                  checked={isFilter(chi.value)}
                                  color={"deep-green-light"}
                                  id={idx}
                                />

                                <p>{chi.label}</p>
                              </span>
                            </div>
                          );
                        })}
                    </>
                  )}
                  {filters &&
                    filters?.map((chi, idx) => {
                      // logger.log(chi, 'fl');
                      return (
                        <React.Fragment key={idx}>
                          <div
                            onClick={() => {
                              if (chi?.filterBy) {
                                setDateRange({
                                  ...dateRange,
                                  filterBy: chi.filterBy,
                                });
                              }
                              activeTab === "type"
                                ? setActiveTab("empty")
                                : setActiveTab("type");
                            }}
                            className="dropdown__date-title"
                          >
                            <p>{chi?.label}</p>

                            {statusFilter && (
                              <div
                                onClick={() => {
                                  activeTab === "type"
                                    ? setActiveTab("type")
                                    : activeTab === "date"
                                    ? setActiveTab("date")
                                    : setActiveTab("empty");
                                  setActiveFilter({
                                    ...activeFilter,
                                    type: [],
                                  });
                                }}
                                className={`dropdown__tags ${
                                  statusFilter && "show"
                                }`}
                              >
                                {/* {activeFilter.type.length === 1 ? (
														<p>{activeFilter?.type[0]?.label}</p>
													) : (
														<p>{activeFilter?.type.length + ' Selected'}</p>
													)} */}
                                <p>{statusFilter?.label}</p>

                                <figure
                                  onClick={() => {
                                    setStatusFilter("");
                                  }}
                                >
                                  {icons.x_circle}
                                </figure>
                              </div>
                            )}

                            <div
                              className={`tag-close-icon ${
                                statusFilter && "hide"
                              }`}
                            >
                              <figure>{icons.chevron_down}</figure>
                            </div>
                          </div>

                          {chi?.options &&
                            chi?.options?.map(
                              (
                                chi: {
                                  filterBy?: string;
                                  value: string;
                                  label:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | React.ReactFragment
                                    | null
                                    | undefined;
                                },
                                idx: number
                              ) => {
                                return (
                                  <div
                                    className={`drop-down__item ${
                                      activeTab === "type" && "active"
                                    }`}
                                    key={idx}
                                  >
                                    <div
                                      onClick={() => {
                                        // setFilter(JSON.stringify(chi));
                                        // statusFilter?.label === chi?.label
                                        // 	? setStatusFilter('')
                                        // 	: setStatusFilter(chi);
                                      }}
                                      className={`${
                                        isFilter(chi.value) && "active"
                                      }`}
                                    >
                                      <RavenCheckBox
                                        onChange={() => {
                                          handleFilter(chi.label as string);
                                          setFilter(JSON.stringify(chi));
                                          statusFilter?.label === chi?.label
                                            ? setStatusFilter("")
                                            : setStatusFilter(chi);
                                        }}
                                        checked={
                                          statusFilter?.value === chi?.value
                                        }
                                        color={"deep-green-light"}
                                        id={idx}
                                      />

                                      <label
                                        style={{ cursor: "pointer" }}
                                        htmlFor={`input-check-${idx}`}
                                      >
                                        {chi.label}
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </React.Fragment>
                      );
                    })}
                </div>

                {/* apply filter button */}
                <div
                  style={{ width: "100%" }}
                  className="dropdown__apply-filter-btn"
                >
                  <RavenButton
                    label="Apply Filter"
                    color="green-light"
                    onClick={reqFilter}
                    loading={loading}
                    style={{ width: "100%", marginTop: "2.5rem" }}
                  />
                </div>
                {/* apply filter ends here */}
              </div>
            </>
          )}
        </div>
      )}
      {/* children drop end */}
    </div>
  );
};
export default FilterFragment;
