import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  // TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ActionContext from "../../../../context/ActionContext";
import { TooltipItem } from "chart.js";
import { formatNumWithCommaNairaSymbol } from "../../../../helper";
// import { ChartTooltipItem } from "chart.js";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    tooltip: {
      usePointStyle: true,
      callbacks: {
        label: function (context: TooltipItem<any>) {
          return `${context.dataset.label}: ${formatNumWithCommaNairaSymbol(
            context.parsed.y
          )}`;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      barPercentage: 0.1,
      categoryPercentage: 0.1,
    },
    y: {
      display: false,
      grid: {
        // display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

interface MyComponentProps {
  chartData?: any;
  labelVal?: any;
}

const InsightBarChart: React.FC<MyComponentProps> = ({
  chartData,
  labelVal,
}) => {
  const [borderColorVal, setBorderColorVal] = useState("#B9DEFE");
  const [backgroundColorVal, setBackgroundColorVal] = useState("#E9F5FF");
  const actionCtx = useContext(ActionContext);
  const labels = ["", ''];
  const data = {
    labels: labelVal || labels,
    datasets: [
      {
        fill: true,
        label: "",
        data: chartData || [10, 39, 49, 47, 52, 95, 30, 72, 91, 53, 24, 100],
        //   borderColor: 'rgba(75,192,192,1)', // Bar border color
        borderWidth: 1.25, // Bar border width
        borderColor: borderColorVal,
        backgroundColor: backgroundColorVal,
        borderRadius: {
          topLeft: 5, // Border radius for top-left corner
          topRight: 5, // Border radius for top-right corner
        },
      },
    ],
  };
  useEffect(() => {
    const theme = localStorage?.getItem("theme");

    if (theme === "light") {
      setBorderColorVal("#B9DEFE");
      setBackgroundColorVal("#E9F5FF");
    }
    if (theme === "dark") {
      setBorderColorVal("#515151");
      setBackgroundColorVal("#333333");
    }
  }, [actionCtx?.themeChange]);
  // console.log(chartData);

  return <Bar options={options as any} data={data} />;
};

export default InsightBarChart;
