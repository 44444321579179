import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "./helper/ScrollTop";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { private_routes_group } from "./routes/private";
import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import NoPageFound from "./fragments/NoPageFound";
import { public_routes_group } from "./routes/public";
import { Provider } from "react-redux";
import store from "./redux/store";
import PrivateRouteDashboard from "./routes/PrivateRoute";
import { RouteLinks } from "./routes/RouteLinks";
import { RavenToast } from "@ravenpay/raven-bank-ui";
import setAuthToken from "./utils/auth";
import SessionEndModal from "./layout/dashboard/SessionExpired";
// import ActionContext from "./context/ActionContext";

function App() {
  const location = useLocation();
  const toks = localStorage.getItem("shambala");
  // console.log(toks);

  toks && setAuthToken();
  // const actionCtx = useContext(ActionContext);
  // const channelID = "";
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   // script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${channelID}`;
  //   script.src = `//fw-cdn.com/10934263/3694369.js`;
  //   script.setAttribute("chat", "true");

  //   // Add an event listener to check when the script has loaded
  //   script.onload = () => {
  //     if (window.fcWidget) {
  //       window.fcWidget.init({
  //         token: '10934263',
  //         host: '3694369',
  //         config: {
  //           cssNames: {
  //             widget: 'custom-freshchat-widget',
  //             button: 'custom-freshchat-button',
  //             // Add more custom class names as needed
  //           },
  //         },
  //       });
  //     }
  //   };

  //   // Append the script to the document's body
  //   document.body.appendChild(script);

  //   // Cleanup by removing the script from the DOM when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  type RouteProp = {
    path: string;
    element: () => any;
    // location:
  };
  return (
    <Provider store={store}>
      <div className="App">
        <Helmet>
          <title>
            Bankbox || Transforming finance with integrated banking
            infrastructure and powerful API services{" "}
          </title>
          {/* <title>The Raven Bank || The People’s Bank of Africa </title> */}
          <meta
            name="description"
            content="Raven Bank allows you to transfer money to other banks for free; Get Loans and up to 15% ROI on your savings plan."
          />
          <meta
            property="og:description"
            content="Raven Bank allows you to transfer money to other banks for free; Get Loans and up to 15% ROI on your savings plan."
          />
          <meta
            name="keywords"
            content="bank, banking, loan, savings, roi, interest, nigeria, africa, fintech,  Speed, fast transactions, corporate account, business account, official account, loan, overdraft, savings, payroll, inventory, invoice, receipts, account management, Fast transfers, speed, fastest bank, digital banking, online banking, savings, high interest savings, fixed savings, target savings, investment, overdraft, Bills Payment, airtime, data, electricity bills payment, cable TV subscription, virtual cards, physical cards, debit cards, virtual dollar cards, virtual naira cards, fast internet (Raven Feather Internet"
          />
          <meta name="page-topic" content="Banking" />
          <meta name="page-type" content="Landing" />
          <meta name="audience" content="Everyone" />
          <meta
            property="og:image"
            content="https://s3.eu-west-2.amazonaws.com/img.creativepool.com/files/candidate/portfolio/full/2022460.jpg"
          />
          <meta property="og:url" content="https://getravenbank.com" />
          <meta name="robots" content="index, follow" />
          <meta
            property="twitter:image:src"
            content="https://s3.eu-west-2.amazonaws.com/img.creativepool.com/files/candidate/portfolio/full/2022460.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="GetRavenBank" />
          <meta
            name="twitter:site"
            content="https://www.twitter.com/theravenbank"
          />
          <meta
            httpEquiv="Content-Security-Policy"
            content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
          />
        </Helmet>
        <ScrollToTop />
        <Routes location={location}>
          <Route
            // location={location}
            path="/"
            element={<Navigate to={RouteLinks.Login} />}
          />
          <Route path="*" element={<NoPageFound />} />
          {/* auth group start */}
          {public_routes_group?.map((route: RouteProp, idx: number) => (
            <Route key={idx} path={route.path} element={<route.element />} />
          ))}
          {/* auth group end */}

          {/* dashboard group start */}
          {private_routes_group?.map((route: RouteProp, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <PrivateRouteDashboard>
                  {<route.element />}
                </PrivateRouteDashboard>
              }
            />
          ))}
          {/* dashboard group end */}
          {/* auth group end */}
          {/* verification group start */}
          {/* {verification_routes_group?.map((route: RouteProp, idx: number) => (
          <Route key={idx} path={route.path} element={<route.element />} />
        ))} */}
          {/* verification group end */}
          {/* dashboard group start */}
          {/* {dashboard_routes_group?.map((route: RouteProp, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={<PrivateRouteDashboard>{<route.element />}</PrivateRouteDashboard>}
            />
          ))} */}
          {/* dashboard group end */}
        </Routes>
        <RavenToast />
      </div>
      <SessionEndModal />
    </Provider>
  );
}

export default App;
