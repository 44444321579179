import React from "react";
import DeleteModal from "../../../../fragments/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../redux/types";
import {
  deleteAlertReceiverFunc,
  // getAlertReceivers,
} from "../../../../redux/insight";
import { trimLongString } from "../../../../helper";

interface MyComponentProps {
  onClose?: () => void;
  visible?: boolean;
  details?: any;
  btnClassName?: string;
  onFinish?:  () => void;
}

const DeleteAlertRecieverModal: React.FC<MyComponentProps> = ({
  onClose,
  visible,
  details,
  btnClassName,
  onFinish,
}) => {
  // const handleSubmit = async () => {};
  const dispatch = useDispatch();
  const { loading_reciept } = useSelector((state: RootState) => state.insights);

  const handleSubmit = async () => {
    // console.log(insight_terminal);
    // console.log(details);
    // console.log(selectedOption);

    const obj = {
      id: String(details?.id),
    };
    // console.log(obj);
    
    const data = await dispatch(deleteAlertReceiverFunc(obj as any) as any);
    if (data?.payload?.status === "success") {
      onFinish && onFinish()
    }
  };

  return (
    <DeleteModal
      loading={loading_reciept}
      btnClassName={btnClassName}
      onDelete={handleSubmit}
      visible={visible}
      onCancel={onClose}
      title={`Delete Alert Receiver ${details?.fname || ""} ${
        Number(details?.lname?.length + details?.fname?.length) > 15
          ? trimLongString(details?.lname, 15)
          : details?.lname || ""
      } ?`}
      deleteText={`Yes, Delete`}
      text={`Are you sure to delete this alert receiver? This action cannot be undone`}
      buttonColor="error-light error-light-two"
    />
  );
};

export default DeleteAlertRecieverModal;
