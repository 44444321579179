import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";
import { OverviewState } from "./types";
// import { ENCRYPTION_KEY_VAL } from "../utils/helpers";
// import { encrypt2 } from "../utils/encrypt";
// import { logger } from '../services/logger';
// import { TeamFormData } from '../pages/dashboard/settings/components/team';
// import { setCookie } from '../utils/helper/Helper';

interface LoginPayload {
  // Add appropriate types for the payload
  duration?: string;
}

interface singleTerminalPayload {
  // Add appropriate types for the payload
  serial?: string;
  perPage?: string | number;
  currentPage?: string | number;
  search?: string;
  start_date?: string;
  stop_date?: string;
  serial_no?: string;
}

export const getOverview = createAsyncThunk(
  "web/all-overview",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/merchant/pdon/overview`);
      // console.log(data);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          //   toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        thunkAPI.dispatch(SET_ALL_OVERVIEW(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getMerchantInfo = createAsyncThunk(
  "web/all-merchant-settlement-info",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/merchant/pdon/getinfo`);
      // console.log(data);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          //   toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data?.data?.data);

        thunkAPI.dispatch(SET_ALL_OVERVIEW_SETTLE(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const requestSettleMentAccountMerchant = createAsyncThunk(
	'/bankbox/request-settle-ment-account-merchant',
	async (payload: any, thunkAPI) => {
		try {
      // console.log(payload);
      
			const { data } = await axios.post(
				`/merchant/pdon/request_settlement`,
				payload
			);

			// console.log(data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// console.log(data.data.data);

				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBankBoxBanks(formatBankList(data?.data?.data)));
				return data;
			}
		} catch (error: any) {
			// console.log(error);

			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportTransaction = createAsyncThunk(
  "web/export-transaction",
  async (payload: singleTerminalPayload, thunkAPI) => {
    // console.log(payload);

    try {
      const { data } = await axios.get(
        `/merchant/pdon/export_transactions?${
          payload.start_date ? "&start_date=" + payload.start_date : ""
        }${payload.stop_date ? "&stop_date=" + payload.stop_date : ""}${
          payload.serial_no ? "&serial_no=" + payload.serial_no : ""
        }${payload.serial_no ? "&serial=" + payload.serial_no : ""}`
      );
      //   console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // thunkAPI.dispatch(SET_ALL_TERMINALS_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const exportOverviewReport = createAsyncThunk(
  "web/export-overview-report",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/merchant/pdon/generate_over_view_report`
      );
      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        //   thunkAPI.dispatch(SET_ALL_INSIGHT(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: OverviewState = {
  loading: false,
  loading_reciept: false,
  overview: {},
  settlement_info: {},
  settle_business_info: {},
  // initialize other state properties
};

export const overViewSlice = createSlice({
  name: "overview",
  initialState,

  reducers: {
    SET_ALL_OVERVIEW: (state, action) => {
      state.overview = action.payload;
    },
    SET_ALL_OVERVIEW_SETTLE: (state, action) => {
      state.settlement_info = action.payload?.settlement_accounts;
      state.settle_business_info = action.payload?.business_info;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOverview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOverview.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getOverview.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMerchantInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMerchantInfo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getMerchantInfo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(exportOverviewReport.pending, (state) => {
      state.loading_reciept = true;
    });
    builder.addCase(exportOverviewReport.fulfilled, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(exportOverviewReport.rejected, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(requestSettleMentAccountMerchant.pending, (state) => {
      state.loading_reciept = true;
    });
    builder.addCase(requestSettleMentAccountMerchant.fulfilled, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(requestSettleMentAccountMerchant.rejected, (state) => {
      state.loading_reciept = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { SET_ALL_OVERVIEW, SET_ALL_OVERVIEW_SETTLE } =
  overViewSlice.actions;

export default overViewSlice.reducer;
