import { RavenInputField, RavenModal, toast } from "@ravenpay/raven-bank-ui";
import React, { useRef, useState } from "react";
import { icons } from "../../../assets/icons";
import "../../../styles/dashboard/modal/ExportModal.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatDate, maskCardNumber } from "../../../utils/helpers";
import {
  // exportTransaction,
  getSingleInsightTerminalTransactionsExport,
} from "../../../redux/insight";
import { decrypt2 } from "../../../utils/encrypt";
import { useLocation } from "react-router-dom";
// import axios from "axios";
import { CSVDownload } from "react-csv";
import {
  capitalizeFirstLetter,
  formatDateHelper,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
} from "../../../helper";
// import {
// 	// capitalizeFirstLetter,
// 	formatDate,
// } from '../../../utils/helper/Helper';
// import { logger } from '../../../services/logger';
// import { bankboxAPI } from '../../../redux/bankbox';

const days = ["Today", "Custom"];
const ExportModal = ({
  visible,
  onClose,
  onClick,
  onChange,
  value,
  page = "wallet",
}: {
  visible?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  page?: string;
  loading?: boolean;
  value?: {
    date:
      | string
      | undefined
      | {
          start_date?: string;
          end_date?: string;
        };
    fileType: string;
  };
  onChange?: any;
}) => {
  const [selectedDay, setSelectedDay] = React.useState<
    | string
    | undefined
    | {
        start_date?: string | undefined;
        end_date?: string | undefined;
      }
  >("Today");
  const [fileType, setFileType] = React.useState("csv");
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const [dateRange, setDateRange] = React.useState<{
    start_date?: string | undefined;
    end_date?: string | undefined;
  }>({
    start_date: "",
    end_date: "",
  });
  const csvLinkRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  //   function getNthDaysAgo(d: number) {
  //     const today = new Date(); // Get today's date
  //     const nthDay = new Date(today); // Create a new date object

  //     nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

  //     return nthDay;
  //   }

  // function downloadFile(url: any, filename: string) {
  // 	const a = document.createElement('a');

  // 	// logger.log(url.url ?? url, 'oinu');
  // 	a.href = url.url ?? url;
  // 	a.download = filename || 'download';
  // 	const clickHandler = () => {
  // 		setTimeout(() => {
  // 			URL.revokeObjectURL(url);
  // 			removeEventListener('click', clickHandler);
  // 		}, 150);
  // 	};

  // 	a.addEventListener('click', clickHandler, false);

  // 	a.click();

  // 	return a;
  // }

  //   function handleFilter(str?: string) {
  //     const seventhDayAgo = getNthDaysAgo(7);
  //     const last30Days = getNthDaysAgo(30);

  //     const formated30days = formatDate(last30Days);
  //     const formated7days = formatDate(seventhDayAgo);
  //     const formated14days = formatDate(getNthDaysAgo(14));

  //     setDateRange({
  //       start_date:
  //         str === "All"
  //           ? formatDate(new Date("2022-06-01"))
  //           : str === "Today"
  //           ? formatDate(new Date())
  //           : str === "Last 7 Days"
  //           ? formated7days
  //           : str === "Last 14 Days"
  //           ? formated14days
  //           : str === "Last 30 Days"
  //           ? formated30days
  //           : dateRange.start_date,
  //       end_date:
  //         str === "All"
  //           ? formatDate(addDays(new Date(), 1))
  //           : str === "Today"
  //           ? formatDate(addDays(new Date(), 1))
  //           : str === "Last 7 Days"
  //           ? formatDate(addDays(new Date(), 1))
  //           : str === "Last 14 Days"
  //           ? formatDate(addDays(new Date(), 1))
  //           : str === "Last 30 Days"
  //           ? formatDate(addDays(new Date(), 1))
  //           : dateRange.end_date,
  //     });
  //   }

  // async function handleExport() {
  //   setLoading(true);
  //   // handleFilter(selectedDay as string);
  //   let val;
  //   // const new

  //   const serialVal = localStorage?.getItem("takwoski");
  //   const decryptedVal = serialVal ? decrypt2(serialVal ?? "") : "";
  //   if (selectedDay === "Today") {
  //     const today = new Date();
  //     const year = today.getFullYear();
  //     const month = today.getMonth() + 1; // Months are zero-based
  //     const day = today.getDate();

  //     const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
  //       day < 10 ? "0" + day : day
  //     }`;
  //     const obj = {
  //       // exportType: fileType,
  //       start_date: formattedDate,
  //       stop_date: formattedDate,
  //       serial_no:
  //         location?.pathname !== "/dashboard-transactions" && serialVal
  //           ? decryptedVal
  //           : "",
  //     };
  //     val = obj;
  //   } else {
  //     const obj = {
  //       // exportType: fileType,
  //       start_date: dateRange?.start_date?.replaceAll("/", "-"),
  //       stop_date: dateRange?.end_date?.replaceAll("/", "-"),
  //       serial_no:
  //         location?.pathname !== "/dashboard-transactions" && serialVal
  //           ? decryptedVal
  //           : "",
  //     };
  //     val = obj;
  //   }

  //   //   console.log(val);
  //   // return
  //   const data = await dispatch(exportTransaction(val as any) as any);
  //   // console.log(data);

  //   if (data?.payload?.status === "success") {
  //     // console.log(data);

  //     const url = data?.payload?.data?.data;
  //     setLoading(false);
  //     // console.log(url);

  //     downloadCsv(url);
  //     // fetchDataFromCSV(url);
  //   } else {
  //     setLoading(false);
  //   }
  // }

  // const downloadCsv = (url: any) => {
  //   window.open(url, "_blank")?.focus();
  // };

  async function handleExportTwo() {
    setLoading(true);
    // handleFilter(selectedDay as string);
    let val;
    // const new

    const serialVal = localStorage?.getItem("takwoski");
    const decryptedVal = serialVal ? decrypt2(serialVal ?? "") : "";
    if (selectedDay === "Today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // Months are zero-based
      const day = today.getDate();

      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      const obj = {
        // exportType: fileType,
        start_date: formattedDate,
        stop_date: formattedDate,
        serial_no:
          location?.pathname !== "/dashboard-transactions" && serialVal
            ? decryptedVal
            : "",
      };
      val = obj;
    } else {
      const obj = {
        // exportType: fileType,
        start_date: dateRange?.end_date?.replaceAll("/", "-"),
        stop_date: dateRange?.start_date?.replaceAll("/", "-"),
        serial_no:
          location?.pathname !== "/dashboard-transactions" && serialVal
            ? decryptedVal
            : "",
      };
      val = obj;
    }

    //   console.log(val);
    // return
    const data = await dispatch(
      getSingleInsightTerminalTransactionsExport(val as any) as any
    );
    // console.log(data);

    if (data?.payload?.status === "success") {
      // console.log(data);
      const dataList = data?.payload?.data?.data?.transactions;
      // console.log(dataList);
      formatExportData(dataList);
      setLoading(false);
      // downloadCsv(url);
    } else {
      setLoading(false);
    }
  }

  const [downloadData, setDownLoadData] = useState<any>([]);
  const csvHeader = [
    { label: "S/N", key: "sn" },
    { label: "Terminal ID", key: "id" },
    { label: "Direction", key: "direction" },
    { label: "System Reference", key: "system_reference" },
    { label: "Business Email", key: "business_email" },
    { label: "Type", key: "type" },
    { label: "Amount", key: "amount" },
    { label: "Fee", key: "fee" },
    { label: "Balance Before", key: "balance_before" },
    { label: "Balance After", key: "balance_after" },
    { label: "Stamp Duty", key: "stamp_duty" },
    { label: "Terminal Label", key: "terminal_label" },
    { label: "Transaction Reference", key: "tranx_reference" },
    { label: "Transaction Details", key: "transaction_details" },
    { label: "Provider", key: "provider" },
    { label: "Date", key: "created_at" },
    { label: "Status", key: "status" },
  ];
  const formatExportData = (param: any) => {
    if (param?.length > 0) {
      const newData = param?.map((chi: any, idx: any) => {
        return {
          sn: idx + 1 < 10 ? `0${idx + 1}` : idx + 1,
          id: chi?.t_id || "NIL",
          direction: chi?.direction
            ? capitalizeFirstLetter(chi?.direction)
            : "NIL",
          system_reference: chi?.reference || "NIL",
          business_email:
            formatMetaData(chi?.pdon_meta)?.terminal?.poseidon_business_email ||
            chi?.business_email ||
            "NIL",
          type: chi?.type ? formatTypeFunction(chi?.type) : "NIL",
          amount: formatNumWithCommaNairaSymbol(chi?.amount) || "NIL",
          fee: formatNumWithCommaNairaSymbol(chi?.fee) || "NIL",
          balance_before: formatNumWithCommaNairaSymbol(chi?.b_before) || "NIL",
          balance_after: formatNumWithCommaNairaSymbol(chi?.b_after) || "NIL",
          stamp_duty: formatNumWithCommaNairaSymbol(chi?.stamp_duty) || "NIL",
          serial:
            chi?.serial ||
            formatMetaData(chi?.pdon_meta)?.terminal?.serial ||
            "NIL",
          terminal_label:
            formatMetaData(chi?.pdon_meta)?.terminal?.tid_label || "NIL",
          tranx_reference:
            chi?.type === "transfer_in"
              ? formatMetaData(chi?.meta_data)?.session_id
              : chi?.rrn || "NIL",
          transaction_details:
            chi?.type === "transfer_in"
              ? `Account Name: ${
                  formatMetaData(chi?.meta_data)?.source?.first_name || ""
                } ${
                  formatMetaData(chi?.meta_data)?.source?.last_name || ""
                }, Account Number: ${
                  formatMetaData(chi?.meta_data)?.source?.account_number
                } ,  Bank: ${formatMetaData(chi?.meta_data)?.source?.bank} `
              : chi?.type === "pdon_card" && !chi?.meta_data
              ? maskCardNumber(formatMetaData(chi?.pdon_meta)?.card?.card_pan)
              : `${chi?.stan}`,
          provider:
            chi?.provider || `${lowerCaseWrap(getPrefix(url))}` || "NIL",
          created_at: chi?.created_at
            ? formatDateHelper(chi?.created_at)?.replace("—", "•")
            : "NIL",
          status: String(chi?.status) === "00" ? `Success` : `Failed`,
        };
      });
      // console.log(newData);
      setDownLoadData(newData);
      downloadCsvTwo();
      onClose && onClose();
      setDateRange((prev) => {
        return { ...prev, start_date: "", end_date: "" };
      });
      setTimeout(() => {
        setDownLoadData([]);
      }, 2000);
      setSelectedDay("Today");
    } else {
      toast.error("No data found", {
        position: "top-right",
      });
    }
  };

  const url =
    process.env.NODE_ENV === "development"
      ? "https://beststar.bankbox.africa/"
      : window.location.href;

  const getPrefix = (param: any) => {
    const paramOne = param?.split("://")[1];
    const paramTwo = paramOne?.split(".bankbox")[0];
    // console.log(paramTwo, "------test------");
    return paramTwo;
  };

  // useEffect(() => {
  //   if (downloadData?.length > 0) {
  //     csvLinkRef.current.link.click();
  //   }
  // }, [downloadData]);

  // getSingleInsightTerminalTransactionsFilter

  const downloadCsvTwo = () => {
    // Call the CSVLink's link.click() method
    if (downloadData?.length > 0) {
      csvLinkRef.current.link.click();
    }
  };

  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      loading={loading}
      disabled={
        selectedDay === "Today"
          ? !fileType
          : selectedDay === "Custom" &&
            (!fileType || !dateRange?.end_date || !dateRange?.start_date)
          ? true
          : false
      }
      btnLabel="Export Data"
      btnColor="deep-green-light"
      // onBtnClick={handleExport}
      onBtnClick={handleExportTwo}
    >
      <div className="export-modal">
        <div className="export-modal__title">
          <h5>Export File</h5>
          <p>Choose how you would like to export your data</p>
        </div>

        <div className="export-modal__export-as">
          <label htmlFor="">Export as</label>
          <div className={"export-modal__export-as--selector-wrap"}>
            <div
              onClick={() => {
                setFileType("csv");
                onChange && onChange({ ...value, fileType: "csv" });
              }}
              className={`selector-wrap__item ${
                fileType === "csv" && "selected"
              }`}
            >
              <figure>
                {fileType === "csv" ? icons.radio_check : icons.radio_unchecked}
              </figure>
              <p>CSV File</p>
            </div>
            <div
              onClick={() => {
                // setFileType("pdf");
                // onChange && onChange({ ...value, fileType: "pdf" });
              }}
              className={`selector-wrap__item ${
                fileType === "pdf" && "selected"
              }`}
              style={{ position: "relative", cursor: "not-allowed" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: ".5rem",
                  right: ".5rem",
                  padding: " .4rem 1rem",
                  borderRadius: "3rem",
                }}
                className="coming-soon border-theme"
              >
                <p style={{ fontSize: "1rem" }} className="text">
                  Coming soon
                </p>
              </div>
              <figure>
                {fileType === "pdf" ? icons.radio_check : icons.radio_unchecked}
              </figure>
              <p>PDF File</p>
            </div>
          </div>

          <div className="export-modal__days-selector">
            <label htmlFor="">Date</label>

            <div className={`days-selector__item-wrap `}>
              {days.map((d, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      if (d === "Today") {
                        setDateRange((prev) => {
                          return {
                            ...prev,
                            end_date: "",
                            start_date: "",
                          };
                        });
                      }
                      setSelectedDay(d);
                      // handleFilter(d as string);
                      // onChange && onChange({ ...value, date: d });
                    }}
                    className={`days-selector__item ${
                      selectedDay === d && "selected"
                    }`}
                  >
                    <p>{d}</p>
                    {selectedDay === d && "selected" && (
                      <figure>{icons.green_checkmark}</figure>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={`export-modal__custom-date ${
              selectedDay === "Custom" && "show"
            }`}
          >
            <RavenInputField
              color="deep-green-dark"
              label="From"
              placeholder="Select a Date"
              type="date"
              dateOptions={{ maxDate: new Date() }}
              value={dateRange?.start_date}
              onChange={(e: string) =>
                setDateRange((prev) => {
                  return { ...prev, start_date: formatDate(new Date(e)) };
                })
              }
            />
            <RavenInputField
              value={dateRange?.end_date}
              color="deep-green-dark"
              label="To"
              placeholder="Select a Date"
              type="date"
              dateOptions={{
                maxDate: new Date(),
                minDate: dateRange?.start_date,
              }}
              onChange={(e: string) =>
                setDateRange((prev) => {
                  return { ...prev, end_date: formatDate(new Date(e)) };
                })
              }
            />
          </div>
        </div>
      </div>
      {/* <CSVLink
        data={downloadData}
        filename={"TransactionExportData.csv"}
        forceDownload={true}
        ref={csvLinkRef}
        headers={csvHeader}
      ></CSVLink> */}
      {downloadData?.length > 0 && (
        <CSVDownload
          data={downloadData}
          ref={csvLinkRef}
          headers={csvHeader}
          filename="my_data.csv"
          target="_blank"
        />
      )}
      ;{/* {downloadData?.length > 0 && (downloadCsvTwo() as any)} */}
    </RavenModal>
  );
};

export default ExportModal;
