import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";
import { AuthState, RegisterPayload } from "./types";
// import { ENCRYPTION_KEY_VAL } from "../utils/helpers";
import { encrypt2 } from "../utils/encrypt";
// import { logger } from '../services/logger';
// import { TeamFormData } from '../pages/dashboard/settings/components/team';
// import { setCookie } from '../utils/helper/Helper';

interface LoginPayload {
  // Add appropriate types for the payload
}

export const registerUser = createAsyncThunk(
  "/register",
  async (payload: RegisterPayload, thunkAPI) => {
    try {
      const data = await axios.post("/web/register", payload);
      // logger.log(data, 'reg');
      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data?.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error?.response?.data?.status === "fail" &&
        error?.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  "web/login_without_otp",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.post("/merchant/pdon/login", payload);
      //   console.log(data);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        // SET_TOKEN(data?.data?.data?.token);
        // localStorage.setItem('shambala', data?.data?.data?.token);
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const loginUserWithOtp = createAsyncThunk(
  "web/login_with_otp",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.post("/merchant/pdon/validate_otp", payload);
      //   console.log(data);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // toast.success("User login successful" || data?.data?.message, {
        //   position: "top-right",
        // });

        // SET_TOKEN(data?.data?.data?.token);
        const encryptedToken = await encrypt2(data?.data?.data ?? "");
        localStorage.setItem("shambala", encryptedToken);
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const initiateForgotPassword = createAsyncThunk(
  "/web/init_forgot_password",
  async (payload: { email?: string }, thunkAPI) => {
    try {
      const { data } = await axios.post("/web/forgot_password", payload);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const validateResetPasswordOTP = createAsyncThunk(
  "/web/verify_password_reset_otp",
  async (payload: { otp?: string }, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/web/verify_password_reset_otp",
        payload
      );

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "web/reset_password",
  async (payload: { otp: string; password: string }, thunkAPI) => {
    try {
      const { data } = await axios.post("web/reset_password", payload);
      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "web/add-support",
  async (payload: { identifier: string; password: string }, thunkAPI) => {
    try {
      const { data } = await axios.post("web/add_support_to_team", payload);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const authenticateLogin = createAsyncThunk(
  "web/authenticate",
  async (payload: { email: string; password: string }, thunkAPI) => {
    try {
      const { data } = await axios.post("web/authenticate", payload);
      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const validateRegistrationOTP = createAsyncThunk(
  "/web/validate_email_verification_code",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/web/validate_email_verification_code",
        payload
      );

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const sendEmailVerification = createAsyncThunk(
  "/web/send_email_verification_code",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/web/send_email_verification_code",
        payload
      );

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  "/web/logout",
  async (payload: any, thunkAPI) => {
    try {
      const { data } = await axios.post("/merchant/pdon/logout");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

// /atlas/public/pdon/partner_info

export const getConfig = createAsyncThunk(
  "/web/get-config",
  async (payload: any, thunkAPI) => {
    // console.log(payload);
    
    try {
      const { data } = await axios.get(`/atlas/pdon/partner_info`);
      // console.log(data);

      if (data?.status === "fail") {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        //   toast.success(data?.data?.message, {
        // 	position: "top-right",
        //   });

        return data;
      }
    } catch (error: any) {
      // console.log(error);

      if (error.message === "Network Error") {
        // toast.error(error.message, {
        //   position: "top-right",
        // });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: AuthState = {
  loading: false,
  isAuth: false,
  logging_out: false,
  token: "",
  loading_config: false,
  // initialize other state properties
};

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    LOGIN: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    },
    SET_TOKEN: (state, action) => {
      state.token = action.payload;
    },
    LOGOUT: (state, action) => {
      state.logging_out = action.payload;
      state.isAuth = true;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authenticateLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(initiateForgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(validateResetPasswordOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConfig.pending, (state) => {
      state.loading_config = true;
    });

    //fullfilled state changes
    builder.addCase(registerUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getConfig.fulfilled, (state) => {
      state.loading_config = false;
    });
    builder.addCase(loginUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(authenticateLogin.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addTeamMember.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(initiateForgotPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(validateResetPasswordOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
    });

    //rejected state changes
    builder.addCase(registerUser.rejected, (state) => {
     state.loading = false;
    });
    builder.addCase(getConfig.rejected, (state) => {
      state.loading_config = false;
    });
    builder.addCase(loginUser.rejected, (state) => {
     state.loading = false;
    });
    builder.addCase(authenticateLogin.rejected, (state) => {
     state.loading = false;
    });
    builder.addCase(addTeamMember.rejected, (state) => {
     state.loading = false;
    });
    builder.addCase(initiateForgotPassword.rejected, (state) => {
     state.loading = false;
    });
    builder.addCase(validateResetPasswordOTP.rejected, (state) => {
     state.loading = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
     state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { LOGIN, LOGOUT, SET_TOKEN } = authSlice.actions;

export default authSlice.reducer;
