import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";
import { InsightState } from "./types";
// import { ENCRYPTION_KEY_VAL } from "../utils/helpers";
// import { encrypt2 } from "../utils/encrypt";
// import { logger } from '../services/logger';
// import { TeamFormData } from '../pages/dashboard/settings/components/team';
// import { setCookie } from '../utils/helper/Helper';

interface LoginPayload {
  // Add appropriate types for the payload
  duration?: string;
}

interface singleTerminalPayload {
  // Add appropriate types for the payload
  serial?: string;
  perPage?: string | number;
  currentPage?: string | number;
  search?: string;
  start_date?: string;
  stop_date?: string;
  serial_no?: string;
  status?: string;
}

interface exportTerminalPayload {
  // Add appropriate types for the payload
  serial?: string;
  start_date?: string;
  stop_date?: string;
  serial_no?: string;
  exportType?: string;
}

interface receiptPayload {
  // Add appropriate types for the payload
  url?: string;
  endDate?: string;
  startDate?: string;
}

export const getAllInsights = createAsyncThunk(
  "web/all-insights",
  async (payload: receiptPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_insight?startDate=${
          payload?.startDate || ""
        }&stopDate=${payload?.endDate || ""}`
      );
      console.log(data, "---------new-------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          // toast.error(data?.data);
        } else
          // toast.error(data?.data, {
          //   position: "top-right",
          // });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data);

        thunkAPI.dispatch(SET_ALL_INSIGHT(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const downloadReceipt = createAsyncThunk(
  "web/download-receipt",
  async (payload: receiptPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/download/${payload?.url}`);
      //   console.log(data);

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        //   thunkAPI.dispatch(SET_ALL_INSIGHT(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getAllInsightTerminals = createAsyncThunk(
  "web/all-insight-terminals",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/merchant/pdon/get_terminals`);
      // console.log(data, "---------------hghj-------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data.data.data);

        thunkAPI.dispatch(SET_ALL_TERMINALS(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getSingleInsightTerminals = createAsyncThunk(
  "web/single-insight-terminals",
  async (payload: singleTerminalPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_terminal?serial=${payload?.serial || ""}`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data);

        thunkAPI.dispatch(SET_SINGLE_TERMINALS(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getSingleInsightTerminalTransactions = createAsyncThunk(
  "web/single-insight-terminals-transactions",
  async (payload: singleTerminalPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_transactions?serial=${payload?.serial || ""}${
          payload?.currentPage ? `&page=${payload?.currentPage}` : ""
        }${payload?.perPage ? `&per_page=${payload?.perPage}` : ""}${
          payload?.search ? `&search=${payload?.search}` : ""
        }`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        //   console.log(data);

        thunkAPI.dispatch(SET_SINGLE_TERMINAL_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getInsightTerminalTransactions = createAsyncThunk(
  "web/insight-terminals-transactions",
  async (payload: singleTerminalPayload, thunkAPI) => {
    // console.log(payload);

    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_transactions?${
          payload?.currentPage ? `&page=${payload?.currentPage}` : ""
        }${payload?.perPage ? `&per_page=${payload?.perPage}` : ""}${
          payload?.search
            ? `&search=${payload?.search}${
                payload.start_date ? "&start_date=" + payload.start_date : ""
              }${payload.stop_date ? "&stop_date=" + payload.stop_date : ""}${
                payload.serial_no ? "&serial_no=" + payload.serial_no : ""
              }`
            : ""
        }`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        thunkAPI.dispatch(SET_ALL_TERMINALS_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getSingleInsightTerminalTransactionsFilter = createAsyncThunk(
  "web/single-insight-terminals-transactions-filter",
  async (payload: singleTerminalPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_transactions?serial=${payload?.serial || ""}${
          payload.start_date ? "&start_date=" + payload.start_date : ""
        }${payload.stop_date ? "&stop_date=" + payload.stop_date : ""}${
          payload.serial_no ? "&serial=" + payload.serial_no : ""
        }${payload.search ? "&search=" + payload.search : ""}`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data);

        thunkAPI.dispatch(SET_SINGLE_TERMINAL_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getSingleInsightTerminalTransactionsExport = createAsyncThunk(
  "web/single-insight-terminals-transactions-export",
  async (payload: singleTerminalPayload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_transactions?${
          payload.start_date ? "start_date=" + payload.start_date : ""
        }${payload.stop_date ? "&stop_date=" + payload.stop_date : ""}${
          payload.serial_no ? "&serial=" + payload.serial_no : ""
        }`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        //   console.log(data);

        // thunkAPI.dispatch(SET_SINGLE_TERMINAL_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getInsightTerminalTransactionsFilter = createAsyncThunk(
  "web/insight-terminals-transactions-filter",
  async (payload: singleTerminalPayload, thunkAPI) => {
    // console.log(payload);

    try {
      const { data } = await axios.get(
        `/merchant/pdon/get_transactions?${
          payload.start_date ? "&start_date=" + payload.start_date : ""
        }${payload.stop_date ? "&stop_date=" + payload.stop_date : ""}${
          payload.serial_no ? "&serial=" + payload.serial_no : ""
        }${payload.search ? "&search=" + payload.search : ""}${
          payload.status ? "&status=" + payload.status : ""
        }`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data);

        thunkAPI.dispatch(SET_ALL_TERMINALS_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const exportTransaction = createAsyncThunk(
  "web/export-transaction",
  async (payload: exportTerminalPayload, thunkAPI) => {
    // console.log(payload);

    try {
      const { data } = await axios.get(
        `/merchant/pdon/export_transactions?${
          payload.exportType ? "file_type=" + payload.exportType : ""
        }${payload.start_date ? "start_date=" + payload.start_date : ""}${
          payload.stop_date ? "&stop_date=" + payload.stop_date : ""
        }${payload.serial_no ? "&serial_no=" + payload.serial_no : ""}${
          payload.serial_no ? "&serial=" + payload.serial_no : ""
        }`
      );
      //   console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // thunkAPI.dispatch(SET_ALL_TERMINALS_TRX(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const addAlertReceiverFunc = createAsyncThunk(
  "web/add-alert-reciever",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/merchant/pdon/add_alert_details",
        payload
      );
      //   console.log(data);/atlas/pdon/add_alert_details

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const deleteAlertReceiverFunc = createAsyncThunk(
  "web/delete-alert-reciever",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/merchant/pdon/remove_alert_details",
        payload
      );
      //   console.log(data);/atlas/pdon/add_alert_details

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          toast.error(data?.data);
        } else
          toast.error(data?.data, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getAlertReceivers = createAsyncThunk(
  "web/get-alert-receiver",
  async (payload: any, thunkAPI) => {
    // console.log(payload);

    try {
      const { data } = await axios.get(
        `merchant/pdon/get_alert_details?tid=${payload?.id}`
      );
      // console.log(data, "------------------");

      if (data?.status === "fail") {
        if (typeof data?.data === "string") {
          // toast.error(data?.data);
        }
        // toast.error(data?.data, {
        //   position: "top-right",
        // });
        else return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        // console.log(data);

        thunkAPI.dispatch(SET_ALL_ALERT_RECEIVERS(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        // toast.error(error.message, {
        //   position: "top-right",
        // });
      }
      if (
        error.response?.data?.status === "fail" &&
        error.response?.status !== 401
      ) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: InsightState = {
  loading: false,
  loading_trx: false,
  loading_alert: false,
  loading_reciept: false,
  insights: {},
  insight_terminals: {},
  insight_terminal: {},
  insight_terminal_trx: {},
  insight_all_terminal_trx: {},
  alert_receivers: [],
  // initialize other state properties
};

export const insightSlice = createSlice({
  name: "insight",
  initialState,

  reducers: {
    SET_ALL_INSIGHT: (state, action) => {
      state.insights = action.payload;
    },
    SET_ALL_TERMINALS: (state, action) => {
      state.insight_terminals = action.payload;
    },
    SET_ALL_ALERT_RECEIVERS: (state, action) => {
      state.alert_receivers = action.payload;
    },
    SET_SINGLE_TERMINALS: (state, action) => {
      state.insight_terminal = action.payload;
    },
    SET_SINGLE_TERMINAL_TRX: (state, action) => {
      state.insight_terminal_trx = action.payload;
    },
    SET_ALL_TERMINALS_TRX: (state, action) => {
      state.insight_all_terminal_trx = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAlertReceivers.pending, (state) => {
      state.loading_alert = true;
    });
    builder.addCase(getAlertReceivers.fulfilled, (state) => {
      state.loading_alert = false;
    });
    builder.addCase(getAlertReceivers.rejected, (state) => {
      state.loading_alert = false;
    });
    builder.addCase(deleteAlertReceiverFunc.pending, (state) => {
      state.loading_reciept = true;
    });
    builder.addCase(deleteAlertReceiverFunc.fulfilled, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(deleteAlertReceiverFunc.rejected, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(downloadReceipt.pending, (state) => {
      state.loading_reciept = true;
    });
    builder.addCase(downloadReceipt.fulfilled, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(downloadReceipt.rejected, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(addAlertReceiverFunc.pending, (state) => {
      state.loading_reciept = true;
    });
    builder.addCase(addAlertReceiverFunc.fulfilled, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(addAlertReceiverFunc.rejected, (state) => {
      state.loading_reciept = false;
    });
    builder.addCase(getAllInsights.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllInsights.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllInsights.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllInsightTerminals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllInsightTerminals.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllInsightTerminals.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSingleInsightTerminals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleInsightTerminals.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getSingleInsightTerminals.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getSingleInsightTerminalTransactionsFilter.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getSingleInsightTerminalTransactionsFilter.fulfilled,
      (state) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getSingleInsightTerminalTransactionsFilter.rejected,
      (state) => {
        state.loading = false;
      }
    );
    builder.addCase(getInsightTerminalTransactionsFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInsightTerminalTransactionsFilter.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getInsightTerminalTransactionsFilter.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getInsightTerminalTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInsightTerminalTransactions.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getInsightTerminalTransactions.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSingleInsightTerminalTransactions.pending, (state) => {
      state.loading_trx = true;
    });
    builder.addCase(getSingleInsightTerminalTransactions.fulfilled, (state) => {
      state.loading_trx = false;
    });
    builder.addCase(getSingleInsightTerminalTransactions.rejected, (state) => {
      state.loading_trx = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  SET_SINGLE_TERMINAL_TRX,
  SET_ALL_INSIGHT,
  SET_ALL_TERMINALS,
  SET_SINGLE_TERMINALS,
  SET_ALL_TERMINALS_TRX,
  SET_ALL_ALERT_RECEIVERS,
} = insightSlice.actions;

export default insightSlice.reducer;
