import React from 'react';
import "./BadgeComponent.css"

interface MyComponentProps {
	text?: string;
	className?: string;
	children?: any;
}

const BadgeComponent: React.FC<MyComponentProps> = ({ text, className, children }) => {
	return (
		<div className={`badge-reusable-wrap-index`}>
			<div className={`badge-wrap-index ${className} grey-bg-two`}>
			{children || ""}	<span>{text || ''}</span>
			</div>
		</div>
	);
};

export default BadgeComponent;