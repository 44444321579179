import axios from "axios";
// import env from "../env";
import { decrypt2, encrypt2 } from "./encrypt";
// import { toast } from "@ravenpay/raven-bank-ui";
// import { lowerCaseWrap } from "../helper";

const API = process.env.REACT_APP_BASE_URL;

/** base url to make request to the BE end point */

const instance = axios.create({
  baseURL: API,
});

// console.log(API);

const getPrefix = (param: any) => {
  const paramOne = param?.split("://")[1];
  const paramTwo = paramOne?.split(".bankbox")[0];
  // console.log(paramTwo, "------test------");
  return paramTwo;
};

instance.interceptors.request.use(
  async (config) => {
    const xPosKey = localStorage.getItem("kasali") || "";
    const decryptedVal = xPosKey && decrypt2(xPosKey ?? "");
    // console.log(decryptedVal);
    // console.log(config);

    const url =
      process.env.NODE_ENV === "development"
      // ? "https://beststar.bankbox.africa/"
        ?   "https://Providus.bankbox.africa/"
        : window.location.href;

    // const url = "https://bankbox.com/";
    // const url = "https://bankboxmerchant.getraventest.com/";https://bankbox.com
    const domain = new URL(url).hostname;
    // console.log("Domain:", domain);
    if (decryptedVal) {
      config.headers["x-pos-key"] = decryptedVal;
    }
    if (domain) {
      config.headers["x-partner"] = `${getPrefix(url)}`;
      // config.headers["x-partner"] = `https://bankbox.com`;
    }
    //    console.log(config, "----------before-------------");

    try {
      const encryptedData = await encrypt2(config.data ?? "");
      config.data = { data: encryptedData };
    } catch (error) {
      // logger.log(error);
    }
    // console.log(config, "------------------------------------");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    // console.log(response);

    response.data.data = decrypt2(response.data.data);
    delete response.config.transformRequest;
    delete response.data?.header;
    // console.log(response, "-----------------------");

    return response;
  },
  async (error) => {
    let val;
    // logger.log(decrypt2(error.response.data.data), 'the error');
    // console.log(error);

    if (error.response.data.status === "fail") {
      val = error.response;
      delete val.config.transformRequest;
    }

    val.data.data = decrypt2(val.data.data);
    // console.log(val);

    return val;
  }
);

export default instance;
