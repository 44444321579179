import React from "react";
import "../styles/fragments/LoadingConfigPage.css";
import loadingGif from "../assets/raven-loader.gif";
import { useSelector } from "react-redux";
import RootState from "../redux/types";
// import ActionContext from "../context/ActionContext";
import NoConfigFound from "./NoConfigFound";

const LoadingConfigPage = () => {
  const { loading_config } = useSelector((state: RootState) => state.auth);
  // const actionCtx = useContext(ActionContext);
  // if (loading_config) {
  //   return (
  //     <div className="loading-congig-wrap-inderx-page">
  //       <div className="loading-wrap">
  //         <figure className="img-box">
  //           <img src={loadingGif} alt="" className="img" />
  //         </figure>
  //       </div>
  //     </div>
  //   );
  // }
  if (!loading_config) {
    return (
      <>
        <NoConfigFound logoutType={true} />
      </>
    );
  }
  return (
    <div className="loading-congig-wrap-inderx-page">
      <div className="loading-wrap">
        <figure className="img-box">
          <img src={loadingGif} alt="" className="img" />
        </figure>
      </div>
    </div>
  );
};

export default LoadingConfigPage;
