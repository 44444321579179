import React from "react";
import "../styles/fragments/TitleTextBox.css";

interface MyComponentProps {
  children?: React.ReactNode;
  // children:
  title?: string;
  text?: string;
  dontShow?: boolean;
  onBack?: () => void;
  back?: boolean;
}

const TitleTextBox: React.FC<MyComponentProps> = ({
  title,
  text,
  children,
  dontShow,
  onBack,
  back,
}) => {
  const backIcon = (
    <svg
      className="img"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.76485 12.5L5.85889 8.5L9.76485 4.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  if (dontShow) {
    return <></>;
  }

  return (
    <div className="title-text-index-box-wrap">
      {back && (
        <div>
          <div
            onClick={() => {
              onBack && onBack();
            }}
            className="back-icon-box grey-bg-two"
          >
            <figure className="img-box">{backIcon}</figure>
          </div>
        </div>
      )}
      <div className="title-text-box">
        <p className="title">{title || "Nothing to see here for now..."}</p>
        <p className="text grey-white-color">{text}</p>
      </div>
      {/* children box start */}
      <div className="children-box">{children}</div>
      {/* children box end */}
    </div>
  );
};

export default TitleTextBox;
