import React from "react";

interface MyComponentProps {
  bgcolor?: string;
  completed?: number;
  baseColor?: string;
  onClick?: () => void;
  pointerCursor?: boolean;
  heightVal?: number | string;
}

const ProgressBar: React.FC<MyComponentProps> = ({
  bgcolor,
  completed,
  baseColor,
  onClick,
  pointerCursor,
  heightVal,
}) => {
  const containerStyles: React.CSSProperties = {
    height: heightVal ? heightVal : 5.5,
    width: "100%",
    alignSelf: "center",
    backgroundColor: baseColor ? baseColor : "",
    borderRadius: 2,
    position: "relative",
    margin: "0rem",
    cursor: pointerCursor ? "pointer" : "pointer"
  };

  const fillerStyles: React.CSSProperties = {
    height: "100%",
    width: `${completed && completed > 100 ? 100 : completed}%`,
    background: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "1s ease 0.3s",
  };

  const labelStyles: React.CSSProperties = {
    padding: 7,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div className="grey-bg" onClick={onClick} style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default ProgressBar;
