import { useContext, useState } from "react";
import AuthLayout from "../../layout/auth";
import "../../styles/auth/LoginPage.css";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import ConfirmOtpModal from "./confirmLoginOtp";
import { validateEmail } from "../../utils/ValidateInput";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/types";
import { loginUser } from "../../redux/auth";
import ActionContext from "../../context/ActionContext";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.auth);
  type showModalProp = {
    confirm_otp: boolean;
    qr_login: boolean;
  };
  const actionCtx = useContext(ActionContext);
  const [showModal, setShowModal] = useState<showModalProp>({
    confirm_otp: false,
    qr_login: false,
  });

  interface formComponent {
    email: string;
  }
  const [details, setDetails] = useState<formComponent>({
    email: "",
  });

  const [showError, setShowError] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async (e?: any) => {
    e && e.preventDefault();
    // setShowModal((prev) => {
    //   return { ...prev, confirm_otp: true };
    // });
    const obj = {
      email: details?.email,
    };
    const data = await dispatch(loginUser(obj as any) as any);
    // console.log(data);
    if (data?.payload?.status === "fail") {
      setShowError(true);
    }
    if (data?.payload?.status === "success") {
      setShowModal((prev) => {
        return { ...prev, confirm_otp: true };
      });
    }
  };

  const handleClick = () => {
    // const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const mailtoLink = `mailto:${actionCtx?.configDetails?.partnerInfo?.email}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      {" "}
      <AuthLayout>
        <div className="login-page-index-wrap">
          {" "}
          <div className="form-wrap-box-one">
            <div className="inner-wrap">
              <p className="sign-title">Sign In</p>
              {/* form start */}
              <form
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!validateEmail(details.email)) {
                    toast.error("Invalid email address!", {
                      position: "top-right",
                    });
                    setShowError(true);
                    return;
                  } else {
                    handleSubmit();
                  }
                }}
                // autoComplete="off"
                className="form"
              >
                <RavenInputField
                  color="green-light"
                  type="email"
                  label={`Email Address * `}
                  placeholder="Enter Email Address"
                  name={`email`}
                  showError={showError}
                  removeAutoComplete={true}
                  value={details?.email}
                  onChange={(e: any) => {
                    showError && setShowError(false);
                    handleChange(e);
                  }}
                />
              </form>
              {/* form end */}
              {/* btn start */}
              <div className="btn-submit-box">
                <form
                  style={{ width: "100%" }}
                  action=""
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!validateEmail(details.email)) {
                      toast.error("Invalid email address!", {
                        position: "top-right",
                      });
                      setShowError(true);
                      return;
                    } else {
                      handleSubmit();
                    }
                  }}
                  className="form"
                >
                  {" "}
                  <RavenInputField
                    loading={loading}
                    type="submit"
                    className="btn"
                    value={`Sign In`}
                    color="green-light"
                    disabled={!details?.email}
                  />
                </form>
              </div>
              {/* btn end */}
            </div>
          </div>
          {/* form wrap box end */}
          {/* contact box start */}
          <div className="contact-box-wrap">
            <p className="text">
              Having Trouble Logging in?{" "}
              <span
                onClick={() => {
                  handleClick();
                }}
              >
                Contact Admin
              </span>
            </p>
          </div>
          {/* contact box end */}
        </div>
      </AuthLayout>
      {/* confirm email modal start */}
      <ConfirmOtpModal
        onResend={handleSubmit}
        onSucess={() => {
          setShowModal((prev) => {
            return { ...prev, confirm_otp: false };
          });
        }}
        showModal={showModal.confirm_otp}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, confirm_otp: false };
          });
        }}
        detail={details}
        loadingParent={loading}
      />
      {/* confirm email modal end */}
    </>
  );
};

export default LoginPage;
