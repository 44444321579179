import clsx from "clsx";
// import { CountryStateCityList } from "./CountryStateCityList";

export function cn(...inputs: clsx.ClassArray) {
  return clsx(inputs);
}

export function generateArray(length: number): number[] {
  return Array.from({ length }, (_, index) => index + 1);
}

export function getAccentColor() {
  let colorVal;
  const accentColor = localStorage.getItem("accent-color");
  if (
    accentColor === "green" ||
    accentColor === "orange" ||
    accentColor === "purple"
  ) {
    const color = accentColor ? `${accentColor}-dark` : "purple-dark";
    colorVal = color;
  } else {
    colorVal = "purple-dark";
  }
  return colorVal;
}

export const sumAllNum = (list?: any) => {
  if (list) {
    const sumVal = list?.reduce((a: any, b: any) => a + b, 0);
    return sumVal;
  }
};

export const ENCRYPTION_KEY_VAL = "tpoagsuebszYhaVque7nFavRoPPjdgab";

export const checkStatusColor = (param: any) => {
  const val =
    String(param) === "0"
      ? "pending"
      : String(param) === "4"
      ? "success"
      : String(param) === "2"
      ? "failed"
      : String(param) === "3"
      ? "info"
      : "pending";
  return val;
};

export const perPageVal = 3;

export function formatDate(date: Date) {
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
}

export function detectCardType(cardNumber: any) {
  // console.log(cardNumber);

  // Visa
  var visaPattern = /^4\d{12}(?:\d{3})?$/;

  // Verve (Verve card numbers typically start with 5061, 5062, 5063, 5064, or 5065)
  var vervePattern = /^(5061|5062|5063|5064|5065)\d{12}$/;

  // Mastercard
  var mastercardPattern = /^(5[1-5]\d{14})$/;

  if (visaPattern.test(cardNumber)) {
    return "Visa";
  } else if (vervePattern.test(cardNumber)) {
    return "Verve";
  } else if (mastercardPattern.test(cardNumber)) {
    return "Master";
  } else {
    return "Verve";
  }
}

export function maskCardNumber(cardNumber: any) {
  if (cardNumber) {
    // Regular expression to match only digits
    var digitPattern = /\d/g;

    // Extract only digits from the input card number
    var digits = cardNumber.match(digitPattern);
    // console.log(digits);

    if (!digits) {
      return "Invalid card number";
    }

    // Replace all but the first 6 and last 4 digits with asterisks
    for (var i = 6; i < digits.length - 4; i++) {
      digits[i] = "*";
    }

    // Join the digits array back into a string
    var maskedNumber = digits.join("");

    // Insert asterisks between the visible digits in the desired format
    return maskedNumber.replace(/(\d{6})(\d+)(\d{4})/, "$1****$3");
  }
}

export const reactSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

export const getPercentageVal = (valOne: any, valTwo: any) => {
  const perVal = Math.round(
    (Number(valOne) / (Number(valOne) + Number(valTwo))) * 100
  );
  // console.log(perVal);
  return perVal;
};

export function generalSearchFunctionality(
  param: string,
  keyToSearch: string[],
  list: any[]
): any[] {
  const lowerCaseValue = param.toLowerCase();

  // Filter the list to include only objects that match the search keys
  const filteredList = list.filter((item) =>
    keyToSearch.some((key) => item[key]?.toLowerCase().includes(lowerCaseValue))
  );

  return filteredList;
}

export function generalSearchFunctionalityRearrange(
  param: any,
  keyToSearch: any,
  list: any
) {
  const lowerCaseValue = param.toLowerCase();
  const compareFunction = (a: any, b: any) => {
    const keysToSearch = keyToSearch;
    //  console.log(details?.authorized_users);
    const aContainsSearchValue = keysToSearch.some((key: any) =>
      a[key].toLowerCase().includes(lowerCaseValue)
    );
    const bContainsSearchValue = keysToSearch.some((key: any) =>
      b[key].toLowerCase().includes(lowerCaseValue)
    );

    if (aContainsSearchValue && !bContainsSearchValue) {
      return -1; // Move a before b
    } else if (!aContainsSearchValue && bContainsSearchValue) {
      return 1; // Move b before a
    } else {
      return 0; // Leave the order unchanged
    }
  };

  const sortedArray = [...list].sort(compareFunction);
  return sortedArray;
}

export function limitDecimalPlaces(
  number: number | undefined,
  decimalPlaces: number
): number | undefined {
  if (number === undefined) return 0; // Return undefined if number is undefined

  const regex = new RegExp(`^\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
  const matchedString = number.toString().match(regex);
  if (matchedString) {
    return parseFloat(matchedString[0]);
  }
  return number;
}

export function formatDateTwo(dateString: any) {
  const date = new Date(dateString);

  // Extract time
  const time = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  // Extract month and year
  const monthYear = date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
  });

  return {
    time: time !== "Invalid Date" ? time : "---",
    monthYear: monthYear !== "Invalid Date" ? monthYear : "---",
  };
}

export function searchGlobalFunction(
  param: any,
  list: any[],
  keysToSearch: string[]
) {
  const lowerCaseValue = param.toLowerCase();
  const compareFunction = (a: any, b: any) => {
    const aContainsSearchValue = keysToSearch?.some((key) =>
      a[key]?.toLowerCase()?.includes(lowerCaseValue)
    );
    const bContainsSearchValue = keysToSearch?.some((key) =>
      b[key]?.toLowerCase()?.includes(lowerCaseValue)
    );

    if (aContainsSearchValue && !bContainsSearchValue) {
      return -1; // Move a before b
    } else if (!aContainsSearchValue && bContainsSearchValue) {
      return 1; // Move b before a
    } else {
      return 0; // Leave the order unchanged
    }
  };
  const sortedArray = list?.slice()?.sort(compareFunction);
  // console.log(sortedArray);
  return sortedArray;
}

interface FormatOptions {
  hideDecimal?: boolean;
  hideSymbol?: boolean;
  convertToNumber?: boolean;
  symbol?: string;
  removeLeadingZero?: boolean;
  numberAbbreviate?: boolean;
}

interface FormatOptions {
  hideDecimal?: boolean;
  hideSymbol?: boolean;
  convertToNumber?: boolean;
  symbol?: string;
  removeLeadingZero?: boolean;
  numberAbbreviate?: boolean;
}

const formatNumberWithSymbols = (number: number) => {
  const formats = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "QT" },
    { value: 1e21, symbol: "QQ" },
  ];

  const format = formats
    .slice()
    .reverse()
    .find((fmt) => number >= fmt.value);

  if (format) {
    return (
      (number / format.value).toFixed(2).replace(/\.00$/, "") + format.symbol
    );
  }

  return number.toString();
};

const getCurrencySymbol = (param: string) => {
  const nairaSymbol = "\u{020A6}";
  const upperParam = String(param).toUpperCase();

  return upperParam === "NGN" || upperParam === "NAIRA"
    ? nairaSymbol
    : upperParam === "USD" || upperParam === "DOLLAR"
    ? "$"
    : upperParam === "EUR" || upperParam === "EURO"
    ? "€"
    : upperParam === "ZAR"
    ? "R"
    : upperParam === "KES"
    ? "KSh"
    : upperParam === "CAD"
    ? "$"
    : upperParam === "GHS"
    ? "₵"
    : upperParam === "GBP" || upperParam === "POUNDS" || upperParam === "POUND"
    ? "£"
    : param;
};

export const formatNumWithCommaNairaSymbolTwo = (
  number: number | string | null | undefined,
  options?: FormatOptions
): string => {
  const symbol = getCurrencySymbol(options?.symbol || "NGN");
  const hideDecimal = options?.hideDecimal || false;
  const hideSymbol = options?.hideSymbol || false;
  const convertToNumber = options?.convertToNumber || false;
  const removeLeadingZero = options?.removeLeadingZero || false;
  const numberAbbreviate = options?.numberAbbreviate || false;

  if (number !== null && number !== undefined) {
    // Remove symbols and non-numeric characters
    let numericValue = number.toString().replace(/[^0-9.]/g, "");

    // Separate the integer and decimal parts
    let [integerPart, decimalPart] = numericValue.split(".");

    if (removeLeadingZero) {
      // Remove leading zeros if more than one leading zero
      if (/^0{2,}/.test(integerPart)) {
        integerPart = integerPart.replace(/^0+/, "");
      }
    }

    if (convertToNumber) {
      // Return number without thousand formatting and without symbol
      const val = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
      return val;
    }

    let formattedValue = Number(
      integerPart + (decimalPart ? "." + decimalPart : "")
    );

    if (numberAbbreviate) {
      let formatted = formatNumberWithSymbols(formattedValue);
      return hideSymbol ? formatted : `${symbol}${formatted}`;
    }

    // Format the integer part with thousand separator
    integerPart = Number(integerPart).toLocaleString();

    // Handle the decimal part
    if (!hideDecimal) {
      if (decimalPart) {
        // Convert the decimal part back to a number and round to two significant figures
        decimalPart = Number("0." + decimalPart)
          .toPrecision(2)
          .split(".")[1];

        // Remove trailing zeros from the decimal part
        decimalPart = decimalPart?.replace(/0+$/, "") || "";
      }

      const val = decimalPart
        ? `${integerPart}${decimalPart ? "." : ""}${decimalPart}`
        : `${integerPart}.00`;

      return hideSymbol ? val : `${symbol}${val}`;
    } else {
      return hideSymbol ? integerPart : `${symbol}${integerPart}`;
    }
  } else {
    return hideSymbol ? `0.00` : `${symbol}0.00`;
  }
};
