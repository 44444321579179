import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import thunk from 'redux-thunk';
import thunkMiddleware from 'redux-thunk';
import { insightSlice } from './insight';
import { overViewSlice } from './overview';
// import * as reduxThunk from 'redux-thunk/extend-redux';

// create a makeStore function
const makeStore = () =>
	configureStore({
		reducer: {
			auth: authSlice.reducer,
			insights: insightSlice.reducer,
			overview: overViewSlice.reducer
		},
		middleware: (getDefaultMiddleware: any) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActionPaths: [
						// 'payload.headers',
						// 'payload.config.transformRequest',
					],
				},
			}).concat(thunk, thunkMiddleware),
	});

const store = makeStore();

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
