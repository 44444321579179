import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../styles/fragments/SelectAndCustomModal.css";
// import Flatpicker from "react-flatpickr";
import moment from "moment";
// import {} from "../../../../utils/helper/Helper";
// import { useDispatch, useSelector } from "react-redux";
//   import { reschedulePayment } from "../../../../redux/schedulePayment";

interface MyComponentProps {
  visible?: boolean;
  onClose?: () => void;
  onFinish?: (param: any) => void;
}

const CustomDateSelectModal: React.FC<MyComponentProps> = ({
  // param,
  visible,
  onClose,
  onFinish,
}) => {
  //   const [reschedule, setReschedule] = useState(false);
  //   const { loading } = useSelector((state) => state.schedule);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  //   const dispatch = useDispatch();
  //   const [details, setDetails] = useState({
  //     date: "",
  //     toDate: "",
  //   });

  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        // setReschedule(false);
        // setDetails({ date: "" });
        setFromDate("");
        setToDate("");
        onClose && onClose();
      }}
      btnColor={`deep-green-light`}
      btnLabel={`Filter by selected date`}
      onBtnClick={() => {
        const obj = `${fromDate}_${toDate}`;
        onFinish && onFinish(obj);
        setTimeout(() => {
          setFromDate("");
          setToDate("");
        }, 1000);
      }}
      disabled={!fromDate || !toDate}
    >
      <>
        <div style={{ paddingBottom: "3rem" }} className="">
          <p
            style={{ fontWeight: "700", fontSize: "1.8rem" }}
            className="title"
          >
            Select Date Range
          </p>
          <div
            className="underline border-theme-bottom"
            style={{ paddingBottom: "unset", margin: "1rem 0rem 3rem 0rem" }}
          ></div>
          <RavenInputField
            style={{ marginBottom: "2rem" }}
            color="deep-green-light"
            label={`From Date`}
            dateOptions={{ maxDate: new Date() }}
            type={`date`}
            value={fromDate}
            onChange={(date: any) => {
              setFromDate(moment(date[0]).format("YYYY-MM-DD"));
            }}
            placeholder={`Select from date`}
          />
          <RavenInputField
            color="deep-green-light"
            label={`To date`}
            type={`date`}
            dateOptions={{ maxDate: new Date() }}
            value={toDate}
            onChange={(date: any) => {
              setToDate(moment(date[0]).format("YYYY-MM-DD"));
            }}
            placeholder={`Select to date`}
          />
          {/* <div className="wrap">
            <Flatpicker
              theme="dark"
              options={{ maxDate: new Date() }}
              value={details.date}
              onChange={(date) => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    date: moment(date[0]).format("YYYY-MM-DD"),
                  };
                });
                // setFromDate(moment(date[0]).format("YYYY-MM-DD HH:mm:ss"));
              }}
              className={`form-input input-calender`}
              autoComplete="off"
              //   placeholder={"Placeholder Here"}
            />
          </div> */}
        </div>
      </>
    </RavenModal>
  );
};

export default CustomDateSelectModal;
