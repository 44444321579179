import React from "react";
// import defaultImg from "../assets/raven-no-content-img.png";
import loadingGif from "../assets/raven-loader.gif";
import "../styles/fragments/NoContentPage.css";

const emptyBgImg = (
  <svg
    className="img"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.5617 29H89.2283V26C89.2283 25.371 88.9785 24.7677 88.5337 24.3229C88.0889 23.8782 87.4857 23.6283 86.8567 23.6283H84.8C84.171 23.6283 83.5678 23.8782 83.123 24.3229C82.6782 24.7677 82.4283 25.371 82.4283 26V29H74.0183V26C74.0183 25.371 73.7685 24.7677 73.3237 24.3229C72.8789 23.8782 72.2757 23.6283 71.6467 23.6283H69.5933C68.9643 23.6283 68.3611 23.8782 67.9163 24.3229C67.4715 24.7677 67.2217 25.371 67.2217 26V29H59.2733V26C59.2733 25.371 59.0235 24.7677 58.5787 24.3229C58.1339 23.8782 57.5307 23.6283 56.9017 23.6283H54.8483C54.2193 23.6283 53.6161 23.8782 53.1713 24.3229C52.7265 24.7677 52.4767 25.371 52.4767 26V29H50.6667C48.4702 29 46.3638 29.8725 44.8107 31.4256C43.2575 32.9787 42.385 35.0852 42.385 37.2816V77.1666C42.3848 78.2543 42.5988 79.3314 43.0149 80.3364C43.431 81.3414 44.041 82.2545 44.8101 83.0237C45.5791 83.7929 46.4921 84.4031 47.497 84.8194C48.5019 85.2357 49.579 85.45 50.6667 85.45H90.5617C91.6494 85.45 92.7264 85.2357 93.7313 84.8194C94.7362 84.4031 95.6492 83.7929 96.4183 83.0237C97.1873 82.2545 97.7973 81.3414 98.2134 80.3364C98.6295 79.3314 98.8436 78.2543 98.8433 77.1666V37.2783C98.8425 35.0824 97.9695 32.9768 96.4165 31.4244C94.8635 29.872 92.7575 29 90.5617 29Z"
      fill="#333333"
    />
    <path
      d="M68.1513 94.2179C78.8367 83.5325 78.8366 66.208 68.1513 55.5226C57.4659 44.8372 40.1414 44.8372 29.456 55.5226C18.7706 66.208 18.7706 83.5325 29.456 94.2179C40.1414 104.903 57.4659 104.903 68.1513 94.2179Z"
      fill="#E5E6E6"
    />
    <path
      d="M48.8032 102.35C33.6499 102.35 21.3232 90.0167 21.3232 74.87C21.3232 59.7233 33.6566 47.39 48.8032 47.39C63.9499 47.39 76.2832 59.7233 76.2832 74.87C76.2832 90.0167 63.9549 102.35 48.8032 102.35ZM48.8032 47.6283C33.7816 47.6283 21.5616 59.8483 21.5616 74.87C21.5616 89.8917 33.7816 102.112 48.8032 102.112C63.8249 102.112 76.0449 89.89 76.0449 74.87C76.0449 59.85 63.8332 47.6283 48.8032 47.6283Z"
      fill="#E5E6E6"
    />
    <path
      d="M61.4166 80.2167H48.38C47.8571 80.2167 47.3555 80.009 46.9858 79.6392C46.616 79.2695 46.4083 78.768 46.4083 78.2451V60.8517C46.4006 60.5879 46.446 60.3252 46.5417 60.0791C46.6373 59.8331 46.7814 59.6088 46.9653 59.4194C47.1492 59.2301 47.3693 59.0796 47.6124 58.9768C47.8556 58.874 48.1168 58.821 48.3808 58.821C48.6448 58.821 48.9061 58.874 49.1492 58.9768C49.3923 59.0796 49.6124 59.2301 49.7963 59.4194C49.9802 59.6088 50.1243 59.8331 50.22 60.0791C50.3156 60.3252 50.361 60.5879 50.3533 60.8517V76.2734H61.4166C61.9396 76.2734 62.4411 76.4811 62.8108 76.8509C63.1806 77.2206 63.3883 77.7221 63.3883 78.2451C63.3883 78.768 63.1806 79.2695 62.8108 79.6392C62.4411 80.009 61.9396 80.2167 61.4166 80.2167Z"
      fill="#333333"
    />
  </svg>
);

interface MyComponentProps {
  img?: any;
  title?: string;
  text?: string;
  loading?: boolean;
}

const defaultText =
  "You have not carried out any transactions Yet \n, once you do it would show up here.";

const NoContentPage: React.FC<MyComponentProps> = ({
  img,
  title,
  text,
  loading,
}) => {
  return (
    <div className="no-content-page-index card-bg">
      {loading ? (
        <>
          <div className="loading-wrap">
            <figure className="img-box">
              <img src={loadingGif} alt="" className="img" />
            </figure>
          </div>
        </>
      ) : (
        <div className="wrap-center-outer-layer grey-bg border-theme">
          <div className="top-box-wrap "></div>
          {/* top end */}
          <div className="bottom-box-wrap table-transaction-cover">
            <div className="wrap-center ">
              <div className="round-img-box table-transaction-cover">
                <div className="round-grey-img grey-bg">
                  <figure className="img-box">{emptyBgImg}</figure>
                </div>
              </div>
              {/* <div className="img-center-box fade-purple-bg grey-bg-two">
            <figure className="img-box">
              <img src={img || defaultImg} alt="" className="img" />
            </figure>
          </div> */}
              <div className="title-text-box">
                <p className="title">
                  {title || "Nothing to see here for now..."}
                </p>
                {text ? (
                  <>
                    {" "}
                    {text.split("\n").map((chi, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          {" "}
                          <p className="text grey-white-color">{chi}</p>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    {defaultText.split("\n").map((chi, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          {" "}
                          <p className="text grey-white-color">{chi}</p>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoContentPage;
