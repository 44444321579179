import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitleTextBox from "../../../fragments/TitleTextBox";
import ContainerWrapper from "../../../fragments/ContainerWrapper";
import "../../../styles/dashboard/terminals/AllTerminals.css";
import {
  RavenInputField,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
// import FilterFragment from "../../../fragments/FilterFragment";
import ExportFragment from "../../../fragments/ExportFragment";
import PaginationFragment from "../../../fragments/PaginationFragment";
import { debounce } from "lodash";
import { generalSearchFunctionality } from "../../../utils/helpers";
import TwoDetailView from "../../../fragments/TwoDetailView";
import BadgeComponent from "../../../fragments/badge/BadgeComponent";
import ActionContext from "../../../context/ActionContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/types";
import { getAllInsightTerminals } from "../../../redux/insight";
import { CSVLink } from "react-csv";
import {
  capitalizeFirstLetter,
  formatNumWithCommaNairaSymbol,
} from "../../../helper";
import { encrypt2 } from "../../../utils/encrypt";
import { RouteLinks } from "../../../routes/RouteLinks";
import PerPageComponent from "../../../fragments/PerPageComponent";
import MobileTableCard from "../../../fragments/MobileTableWrap";

const AllTerminals = () => {
  //   const insight_all_terminal_trx = "" as any;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPageVal, setCurrentPageVal] = useState(1);
  //   const [search, setSearch] = useState("");
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, insight_terminals } = useSelector(
    (state: RootState) => state.insights
  );

  const tableList = [
    "TERMINAL DETAIL",
    "SERIAL NO.",
    "TRANSFERS",
    "CARD  TRANSACTION",
    "TOTAL AMOUNT",
    "STATUS",
  ];

  useEffect(() => {
    if (Object?.keys(actionCtx?.configDetails)?.length > 0) {
      getAllInsightsTerminalsFunc();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.configDetails]);

  const getAllInsightsTerminalsFunc = async () => {
    dispatch(getAllInsightTerminals({} as any) as any);
  };
  const [search, setSearch] = useState("");
  const [all_terminals, setAllTerminals] = useState<any>({});
  useEffect(() => {
    if (insight_terminals?.data?.length > 0) {
      paginateArray(
        insight_terminals?.data,
        actionCtx?.perPageGlobalVal?.value,
        currentPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insight_terminals, currentPage, actionCtx.perPageGlobalVal, actionCtx?.configDetails]);

  const handleSearch = debounce(async (param: any) => {
    const newList = generalSearchFunctionality(
      param,
      ["serial", "tid", "tid_label"],
      insight_terminals?.data
    );
    paginateArray(newList, actionCtx?.perPageGlobalVal?.value, currentPage);
  }, 50);

  //   const actionCtx?.perPageGlobalVal?.value = 5;
  interface PaginationResult<T> {
    currentPage: number;
    perPageArrays: T[][];
    totalPages: number;
    nextPage: number | null;
    prevPage: number | null;
    arrayToShow: any;
    startIndex: number;
    endIndex: number;
  }

  function paginateArray<T>(
    array: T[],
    pageSize: number,
    currentPage: number
  ): PaginationResult<T> {
    const perPageArrays: T[][] = [];
    const totalPages = Math.ceil(array.length / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, array.length);

    for (let i = 0; i < totalPages; i++) {
      const startIndex = i * pageSize;
      const endIndex = Math.min(startIndex + pageSize, array.length);
      perPageArrays.push(array.slice(startIndex, endIndex));
    }

    const obj = {
      currentPage,
      perPageArrays,
      totalPages,
      startIndex: startIndex + 1,
      endIndex,
      arrayToShow: perPageArrays[currentPage - 1],
      nextPage: currentPage < totalPages ? currentPage + 1 : null,
      prevPage: currentPage > 1 ? currentPage - 1 : null,
    };
    setAllTerminals(obj);
    return obj;
  }

  const CsvHeader = [
    { label: "S/N", key: "num" },
    { label: "TERMINAL LABEL", key: "tid_label" },
    { label: "TERMINAL ID", key: "tid" },
    { label: "SERIAL NO", key: "serial" },
    { label: "BANK TRANSFER AMOUNT", key: "transfer_volume" },
    { label: "CARD TRANSFER AMOUNT", key: "card_volume" },
    { label: "TOTAL AMOUNT", key: "cal" },
    { label: "STATUS", key: "status" },
  ];

  const formatExportData = (data: any) => {
    const list = data?.map((chi: any, idx: any) => {
      return {
        num: idx < 10 ? `0${idx + 1}` : idx + 1,
        tid_label: capitalizeFirstLetter(chi?.tid_label),
        tid: chi?.tid,
        serial: chi?.tid_label,
        transfer_volume: formatNumWithCommaNairaSymbol(
          String(chi?.transfer_volume)
        ),
        card_volume: formatNumWithCommaNairaSymbol(String(chi?.card_volume)),
        cal: formatNumWithCommaNairaSymbol(
          String(Number(chi?.transfer_volume) + Number(chi?.card_volume))
        ),
        status: "success",
      };
    });
    return list;
  };

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitleTextBox
          text="View All Bankbox devices on the platform."
          title="All Terminals"
        />
        {/* title box end */}
        {/* container wrap start */}
        <ContainerWrapper
          noContent={Object?.keys(insight_terminals)?.length < 1}
          dontShowHeader
          width={`100%`}
          noContentLoading={loading}
        >
          <div
            // onClick={() => {
            //   console.log(insight_terminals?.data);
            // }}
            className="all-terminal-dashboard-index-wrap"
          >
            {/* serch export filter box start */}
            <div className="search-export-filter-box">
              {/* search box start */}
              <form
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="search-box"
                autoComplete="off"
              >
                <RavenInputField
                  color="green-light"
                  type="search"
                  placeholder="Search Transactions"
                  value={search}
                  onChange={(e: any) => {
                    handleSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                />
              </form>
              {/* search box end */}
              {/* filter-export pagination box start */}
              <div className="filter-export-pagination-box">
                {/* <FilterFragment page="all-transactions" /> */}

                <CSVLink
                  // ref={download}

                  className="export-table-content-box tooltip-hover-wrap grey-bg"
                  filename={"All Terminals.csv"}
                  data={formatExportData(all_terminals?.arrayToShow) || ""}
                  headers={CsvHeader}
                >
                  {" "}
                  <RavenToolTip
                    color="black-light"
                    text="Export Table Content"
                    position={`left`}
                  />
                  <ExportFragment
                    onClick={() => {
                      // setShowModal((prev) => {
                      //   return { ...prev, export: true };
                      // });
                    }}
                  />
                </CSVLink>
                <PaginationFragment
                  defaultTo={all_terminals?.endIndex}
                  defaultfrom={all_terminals?.startIndex}
                  from={1}
                  total={insight_terminals?.data?.length}
                  perPageVal={actionCtx?.perPageGlobalVal?.value || "1"}
                  next={all_terminals?.nextPage}
                  prev={all_terminals?.prevPage}
                  onPrev={() => {
                    setCurrentPage(all_terminals?.prevPage);
                  }}
                  onNext={() => {
                    setCurrentPage(all_terminals?.nextPage);
                  }}
                />
                <PerPageComponent />
              </div>
              {/* filter-export pagination box end */}
            </div>
            {/* serch export filter box end */}
            {/* table content start */}
            <div
              //   onClick={() => {
              //     console.log(all_terminals?.arrayToShow);
              //   }}
              className="table-content-box"
            >
              {/* big table start  */}
              <div className="big-table">
                <RavenTable headerList={tableList}>
                  {all_terminals?.arrayToShow?.map((chi: any, idx: any) => {
                    return (
                      <RavenTableRow
                        //   loading={loading}
                        key={idx}
                        one={
                          <TwoDetailView
                            title={capitalizeFirstLetter(chi?.tid_label)}
                            text_one={chi?.tid}
                          />
                        }
                        two={chi?.serial}
                        three={
                          <TwoDetailView
                            title={formatNumWithCommaNairaSymbol(
                              String(chi?.transfer_volume)
                            )}
                            // title={chi?.card_amount}
                            // text_two={chi?.stan}
                          />
                        }
                        // three={chi?.t_id || "---"}
                        four={
                          <TwoDetailView
                            // text_one={chi?.bank_count}
                            title={formatNumWithCommaNairaSymbol(
                              String(chi?.card_volume)
                            )}
                            // text_two={chi?.stan}
                          />
                        }
                        five={
                          <TwoDetailView
                            // text_one={chi?.bank_count}
                            title={formatNumWithCommaNairaSymbol(
                              String(
                                Number(chi?.transfer_volume) +
                                  Number(chi?.card_volume)
                              )
                            )}
                            // text_two={chi?.stan}
                          />
                        }
                        six={
                          <BadgeComponent
                            className={
                              Number(chi?.status) === 1 ? "success" : `failed`
                            }
                            text={
                              Number(chi?.status) === 1 ? `Active` : `Inactive`
                            }
                          />
                        }
                        onRowClick={async () => {
                          //   console.log(chi);

                          const encryptedSerial = await encrypt2(
                            chi?.serial ?? ""
                          );
                          localStorage?.setItem("takwoski", encryptedSerial);
                          navigate(RouteLinks?.Terminal_details);
                        }}
                        // six={chi?.date}
                      />
                    );
                  })}
                </RavenTable>
              </div>
              {/* big table end */}
              {/* mobile wrap table start */}
              <div className="mobile-table-box">
                {all_terminals?.arrayToShow?.map((chi: any, idx: any) => {
                  return (
                    <MobileTableCard
                      amountColor={``}
                      text={`TID: ${chi?.tid}` || "---"}
                      textTwo={`Serial: ${chi?.serial}`}
                      title={capitalizeFirstLetter(chi?.tid_label) || "---"}
                      amount={
                        formatNumWithCommaNairaSymbol(
                          String(
                            Number(chi?.transfer_volume) +
                              Number(chi?.card_volume)
                          )
                        ) || "---"
                      }
                      key={idx}
                      onRowClick={async () => {
                        const encryptedSerial = await encrypt2(
                          chi?.serial ?? ""
                        );
                        localStorage?.setItem("takwoski", encryptedSerial);
                        navigate(RouteLinks?.Terminal_details);
                      }}
                      avatar={chi?.tid_label}
                      showImg
                      //   img={}
                      //   smallText={ref}
                      //   type={chi?.direction || " "}
                    />
                  );
                })}
              </div>
              {/* mobile wrrap table end */}
            </div>
            {/* table content end */}
          </div>
        </ContainerWrapper>
        {/* container wrap end */}
      </DashboardLayout>
    </>
  );
};

export default AllTerminals;
