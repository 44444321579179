import React, { useContext, useEffect, useState } from "react";
// import FilterFragment from "../../../fragments/FilterFragment";
import MultiProgress from "react-multi-progress";
import ActionContext from "../../../context/ActionContext";
import InsightBarChart from "../insight/charts/InsightBarChart";
import {
  formatNumWithCommaNaira,
  formatNumWithCommaNairaSymbol,
} from "../../../helper";
import { useSelector } from "react-redux";
import RootState from "../../../redux/types";
import { getPercentageVal, sumAllNum } from "../../../utils/helpers";

const BankTransferDetails = () => {
  const [chartLine, setChartline] = useState(true);
  const actionCtx = useContext(ActionContext);
  const [chartData, setchartData] = useState<[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading, overview } = useSelector(
    (state: RootState) => state.overview
  );

  useEffect(() => {
    const theme = localStorage?.getItem("theme");

    if (theme === "light") {
      setChartline(true);
    }
    if (theme === "dark") {
      setChartline(false);
    }
  }, [actionCtx?.themeChange]);

  const formatChatData = (param: any) => {
    // console.log(param);

    if (param?.length > 0) {
      const newList = param?.map((chi: any) => chi?.volume);
      //   console.log(newList);

      return newList;
    }
  };
  const [labelVal, setLabelVal] = useState<any>([]);
  const formatLabelChartData = (param: any) => {
    // console.log(param);
    function formatMonth(inputDateStr: string) {
      const parts = inputDateStr.split(" "); // Split the string by space
      const month = parts[1].substring(0, 3); // Get the first 3 characters of the month
      const formattedDate = `${parts[0]} ${month} ${parts[2]}`; // Concatenate day, abbreviated month, and year
      return formattedDate;
    }
    if (param?.length > 0) {
      const newList = param?.map((chi: any) => formatMonth(chi?.period));
      //   console.log(newList);

      return newList;
    }
  };

  useEffect(() => {
    // console.log(overview);

    // setchartData(formatChatData(chartDataVal));
    if (overview?.bankCollectionChartData?.length > 0) {
        // console.log(overview?.cardChartData);

      setchartData(formatChatData(overview?.bankCollectionChartData));
      setLabelVal(formatLabelChartData(overview?.bankCollectionChartData));
    }
    // console.log(overview);
  }, [overview]);

  return (
    <>
      {/* left content wrap start */}
      <div className="left-content-wrap border-theme-right">
        <div className="wrapper-content">
          {/* multibar content start */}
          <div className="multi-bar-content-box">
            <MultiProgress
              transitionTime={1.2}
              elements={[
                {
                  value: getPercentageVal(
                    overview?.lowerSection?.collection?.successful
                      .total_volume,
                    overview?.lowerSection?.collection?.failed.total_volume
                  ),
                  color: "#1ACE37",
                },
                {
                  value: getPercentageVal(
                    overview?.lowerSection?.collection?.failed.total_volume,
                    overview?.lowerSection?.collection?.successful
                      .total_volume
                  ),
                  color: "#FF0F00",
                },
              ]}
              roundLastElement={false}
              backgroundColor="#ECEDED"
              round={false}
              height={20}
              className="bar"
            />
          </div>
          {/* multibar content end */}
          {/* info detail box wrap start */}
          <div className="info-details-box-wrap">
            {/* total detail card start */}
            <div className="total-detail-card-box card-bg">
              {/* icon wrap start */}
              <div className="icon-wrap">
                <figure className="img-box">
                  <svg
                    className="img"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8824 4.39453L6.73242 11.5445L3.48242 8.29453"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </div>
              {/* icon wrap end */}
              <p className="card-title grey-white-color-white">
                successful Bank COLLECTIONS
              </p>
              <p className="card-volume">
                {" "}
                {formatNumWithCommaNairaSymbol(
                  String(
                    overview?.lowerSection?.collection?.successful
                      .total_volume || "0"
                  )
                ) || "---"}
              </p>
              <div className="card-amount-box border-theme-top">
                <p className="card-amount grey-white-color-white">
                  Count:{" "}
                  <span
                    className="black-white-color"
                    style={{ fontWeight: "600" }}
                  >
                    {formatNumWithCommaNaira(
                      String(
                        overview?.lowerSection?.collection?.successful
                          .total_count || "0"
                      )
                    ) || "---"}
                  </span>
                </p>
              </div>
            </div>
            {/* total detail card end */}
            {/* total detail card start */}

            {/* total detail card end */}
            {/* total detail card start */}
            <div className="total-detail-card-box card-bg">
              {/* icon wrap start */}
              <div className="icon-wrap icon-wrap-failed">
                <figure className="img-box">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M4.08809 3.69893L13.2791 12.8899M15.1836 8.29443C15.1836 11.8843 12.2734 14.7944 8.68359 14.7944C5.09374 14.7944 2.18359 11.8843 2.18359 8.29443C2.18359 4.70458 5.09374 1.79443 8.68359 1.79443C12.2734 1.79443 15.1836 4.70458 15.1836 8.29443Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </div>
              {/* icon wrap end */}
              <p className="card-title grey-white-color-white">
                failed bank COLLECTIONS
              </p>
              <p className="card-volume">
                {" "}
                {formatNumWithCommaNairaSymbol(
                  String(
                    overview?.lowerSection?.collection?.failed
                      .total_volume || "0"
                  )
                ) || "---"}
              </p>
              <div className="card-amount-box border-theme-top">
                <p className="card-amount grey-white-color-white">
                  Count:{" "}
                  <span
                    className="black-white-color"
                    style={{ fontWeight: "600" }}
                  >
                    {formatNumWithCommaNaira(
                      String(
                        overview?.lowerSection?.collection?.failed
                          .total_count || "0"
                      )
                    ) || "---"}
                  </span>
                </p>
              </div>
            </div>
            {/* total detail card end */}
          </div>
          {/* info detail box wrap end */}
        </div>
      </div>
      {/* left content wrap end */}
      {/* right content wrap start */}
      <div className="right-content-wrap">
        {/* title amount filter box start */}
        <div className="title-amount-filter-box">
          <div className="title-amount-box">
            <p className="title grey-white-color">
              Overall Transaction Volume{" "}
            </p>
            <p className="amount">  {formatNumWithCommaNairaSymbol(sumAllNum(chartData))}</p>
          </div>
          {/* <div className="filter-box">
            <FilterFragment />
          </div> */}
        </div>
        {/* title amount filter box end */}
        {/* chart wrap box start */}
        <div
          className={`chart-wrap-box chart-content-box-wrap ${
            chartLine && "chart-content-box-wrap-light"
          }`}
        >
          <div className="wrapper">
            <InsightBarChart chartData={chartData} labelVal={labelVal} />
          </div>
        </div>
        {/* chart wrap box end */}
      </div>
      {/* right content wrap end */}
    </>
  );
};

export default BankTransferDetails;
