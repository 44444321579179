import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";

interface MyComponentProps {
  title?: string;
  text_one?: string;
  text_two?: string;
  copy?: boolean;
}

const TwoDetailView: React.FC<MyComponentProps> = ({
  title,
  text_one,
  text_two,
  copy,
}) => {
  const copyIcon = (
    <svg
      className="img"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "60%", height: "60%", objectFit: "contain" }}
    >
      <path
        d="M3.75 1.5H7.3C8.42011 1.5 8.98016 1.5 9.40798 1.71799C9.78431 1.90973 10.0903 2.21569 10.282 2.59202C10.5 3.01984 10.5 3.5799 10.5 4.7V8.25M3.1 10.5H7.15C7.71005 10.5 7.99008 10.5 8.20399 10.391C8.39215 10.2951 8.54513 10.1422 8.64101 9.95399C8.75 9.74008 8.75 9.46005 8.75 8.9V4.85C8.75 4.28995 8.75 4.00992 8.64101 3.79601C8.54513 3.60785 8.39215 3.45487 8.20399 3.35899C7.99008 3.25 7.71005 3.25 7.15 3.25H3.1C2.53995 3.25 2.25992 3.25 2.04601 3.35899C1.85785 3.45487 1.70487 3.60785 1.60899 3.79601C1.5 4.00992 1.5 4.28995 1.5 4.85V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5Z"
        stroke="var(--primary-base-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [copyItem, setCopyItem] = useState<any>("");
  const handleCopy = async (param: any) => {
    setCopyItem(param);
    await navigator.clipboard.writeText(param);
    setTimeout(() => {
      setCopyItem("");
    }, 1000);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: ".4rem" }}>
      <p style={{ fontWeight: "600", fontSize: "1.4rem" }} className="title">
        {title || ""}
      </p>
      {text_one && (
        <div
          style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
          className="text-boxes"
        >
          <p
            style={{ fontSize: "1.2rem" }}
            className="text-one grey-white-color"
          >
            {text_one || ""}
          </p>
          {text_two ? (
            <>
              <span className="grey-white-color">{"•"}</span>
            </>
          ) : (
            ""
          )}
          <p
            style={{ fontSize: "1.2rem" }}
            className="text-two grey-white-color"
          >
            {text_two || ""}
          </p>
          {copy && (
            <>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy(copy);
                }}
                style={{ marginLeft: "1rem", cursor: "pointer" }}
              >
                {copyItem ? (
                  <div
                    className={`grey-bg-two`}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <FaCheck
                      style={{
                        width: "60%",
                        height: "60%",
                        objectFit: "contain",
                        color: "var(--primary-base-color)",
                      }}
                    />
                  </div>
                ) : (
                  <figure
                    className={`grey-bg-two`}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    {copyIcon}
                  </figure>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TwoDetailView;
