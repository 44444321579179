import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { RouteLinks } from "./RouteLinks";
import instance from "../utils/axios";
// import { decrypt2 } from "../utils/encrypt";
import { toast } from "@ravenpay/raven-bank-ui";
import ActionContext from "../context/ActionContext";
// import { toast } from '@ravenpay/raven-bank-ui';

export const LOGOUT = async () => {
  localStorage.clear();
  // setCookie('shambala', '', 0);
};

const PrivateRouteDashboard = ({ children }: any) => {
  // let isLoggingOut = false; // global
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const urlSave = location.pathname.split("/")[1];

  instance.interceptors.response.use(
    async (response: any) => {
      // console.log(response);
      // if (response?.dat?.data === "unable to resolve partner details") {
      //   return;
      // }
      if (
        response?.data?.data === "token expired" ||
        response?.data?.data === "invalid token"
      ) {
        // console.log(response?.data?.data);
        actionCtx?.setSessionExpire(true);
        // console.log("kjhgs");
        return;
      } else {
        if (response?.data?.status === "fail") {
          // console.log(response);

          response.data.data &&
            response?.data?.data !== "unable to resolve partner details" &&
            toast.error(response.data.data);
        }
      }

      return response;
    },
    async (error) => {
      //   console.log(error);

      return error;
    }
  );

  const authenticated = localStorage.getItem("shambala");

  return authenticated
    ? children
    : window.location.replace(`${RouteLinks?.Login}?${urlSave}`);
};

export default PrivateRouteDashboard;
