import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../styles/dashboard/modal/TransactionDetailModal.css";
import BadgeComponent from "../../../fragments/badge/BadgeComponent";
import {
  formatDate,
  formatMetaData,
  formatNUmPan,
  formatNumWithCommaNairaSymbol,
  nairaSymbol,
  // formatTypeFunction,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/types";
import { downloadReceipt } from "../../../redux/insight";
import { limitDecimalPlaces } from "../../../utils/helpers";

interface MyComponentProps {
  visible?: boolean;
  onClose?: () => void;
  detail?: any;
}

const TransactionDetailModal: React.FC<MyComponentProps> = ({
  visible,
  onClose,
  detail,
}) => {
  const switchList = ["Basic Details", "Device Details"];
  const [activeSelect, setActiveSelect] = useState("Basic Details");
  const dispatch = useDispatch();
  const { loading_reciept } = useSelector((state: RootState) => state.insights);

  const handleDownloadReciept = async () => {
    const obj = {
      url:
        detail?.type === "pdon_card"
          ? `card_receipt?rrn=${detail?.rrn}`
          : `bank_receipt?ref=${detail?.reference}`,
    };
    // console.log(obj);
    const data = await dispatch(downloadReceipt(obj as any) as any);
    // console.log(data);

    if (data?.payload?.status === "success") {
      const url = data?.payload?.data?.data;
      //   setLoading(false);
      downloadCsv(url);
    }
  };

  const downloadCsv = (url: any) => {
    window.open(url, "_blank")?.focus();
  };

  return (
    <RavenModal
      btnLabel="Download Receipt"
      onBtnClick={() => {
        handleDownloadReciept();
        // console.log(detail);
        // console.log(formatMetaData(detail?.meta_data));
      }}
      visble={visible}
      onClose={onClose}
      btnColor="green-light"
      loading={loading_reciept}
    >
      <div
        // onClick={() => {
        //   console.log(detail);
        // }}
        className="transaction-detail-modal-index-wrap"
      >
        <p className="title">Transaction Details</p>
        {/* swtitch box start */}
        <div className="switch-list-box grey-bg">
          {switchList?.map((chi, idx) => {
            return (
              <div
                onClick={() => {
                  activeSelect !== chi && setActiveSelect(chi);
                }}
                key={idx}
                className={`switch-item ${
                  activeSelect === chi && "switch-item-active white-black-bg"
                }`}
              >
                <span className="grey-white-color">{chi}</span>
              </div>
            );
          })}
        </div>
        {/* swtitch box end */}
        {/* content box start */}
        <div className="content-down-box-wrap">
          {/* basic start */}
          {activeSelect === "Basic Details" && (
            <>
              <div className="content-basic-device-wrap border-theme">
                {/* label value box start */}
                {detail?.direction && (
                  <div className="label-value-box border-theme-bottom">
                    <p className="label">Direction</p>
                    <div className="value">
                      <BadgeComponent
                        className={
                          detail?.direction === "credit" ? `success` : "failed"
                        }
                        text={detail?.direction || " "}
                      />
                    </div>
                  </div>
                )}
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Amount</p>
                  <div className="value">
                    <span>
                      {formatNumWithCommaNairaSymbol(detail?.amount) || "---"}
                    </span>
                  </div>
                </div>
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Fee</p>
                  <div className="value">
                    <span>
                      {`${nairaSymbol}${limitDecimalPlaces(
                        formatMetaData(detail?.meta_data)?.merchant_fee,
                        2
                      )}` || "---"}
                    </span>
                  </div>
                </div>
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Refrence</p>
                  <div className="value">
                    <span>{detail?.reference || "---"}</span>
                  </div>
                </div>
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Type</p>
                  <div className="value">
                    <span>
                      {detail?.type === "transfer_out"
                        ? "Bank Transfer"
                        : detail?.type === "transfer_in"
                        ? "Bank Collection"
                        : "Card"}
                    </span>
                  </div>
                </div>
                {/* label value box end */}
                {/* card type start */}
                {detail?.type === "transfer_out" ||
                detail?.type === "pdon_card" ? (
                  <>
                    {" "}
                    {/* label value box start */}
                    <div className="label-value-box border-theme-bottom">
                      <p className="label">Card Pan</p>
                      <div className="value">
                        <span>
                          {formatNUmPan(
                            formatMetaData(detail?.pdon_meta)?.card?.card_pan
                          ) ||
                            formatNUmPan(
                              formatMetaData(detail?.meta_data)?.card_pan
                            ) ||
                            "---"}
                        </span>
                      </div>
                    </div>
                    {/* label value box end */}
                    {/* label value box start */}
                    <div className="label-value-box border-theme-bottom">
                      <p className="label">RRN</p>
                      <div className="value">
                        <span>{detail?.rrn || "---"}</span>
                      </div>
                    </div>
                    {/* label value box end */}
                    {/* label value box start */}
                    <div className="label-value-box border-theme-bottom">
                      <p className="label">Stan</p>
                      <div className="value">
                        <span>
                          {detail?.stan ||
                            formatMetaData(detail?.pdon_meta)?.card?.stan ||
                            "---"}
                        </span>
                      </div>
                    </div>
                    {/* label value box end */}
                  </>
                ) : (
                  ""
                )}
                {/* card type end */}
                {/* transfer type start */}
                {detail?.type === "pdon_transfer" && (
                  <>
                    {" "}
                    {/* label value box start */}
                    <div className="label-value-box border-theme-bottom">
                      <p className="label">Recipient Name</p>
                      <div className="value">
                        <span>Adeeko Emmauel</span>
                      </div>
                    </div>
                    {/* label value box end */}
                    {/* label value box start */}
                    <div className="label-value-box border-theme-bottom">
                      <p className="label">Account No.</p>
                      <div className="value">
                        <span>6859438374576</span>
                      </div>
                    </div>
                    {/* label value box end */}
                    {/* label value box start */}
                    <div className="label-value-box border-theme-bottom">
                      <p className="label">Bank</p>
                      <div className="value">
                        <span>Firstbank</span>
                      </div>
                    </div>
                    {/* label value box end */}
                  </>
                )}
                {/* transfer type end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Date</p>
                  <div className="value">
                    <span>
                      {detail?.created_at
                        ? formatDate(detail?.created_at)
                        : "---"}
                    </span>
                  </div>
                </div>
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Status</p>
                  <div className="value">
                    <BadgeComponent
                      text={
                        String(detail?.status) === "00" ? `Success` : `Failed`
                      }
                      className={
                        String(detail?.status) === "00" ? `success` : `failed`
                      }
                    />
                  </div>
                </div>
                {/* label value box end */}
              </div>
            </>
          )}
          {/* basic end */}
          {/* Device start */}
          {activeSelect === "Device Details" && (
            <>
              <div className="content-basic-device-wrap border-theme">
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Serial No</p>
                  <div className="value">
                    <span>
                      {detail?.serial ||
                        formatMetaData(detail?.pdon_meta)?.card?.serial ||
                        "---"}
                    </span>
                  </div>
                </div>
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Terminal ID</p>
                  <div className="value">
                    <span>
                      {detail?.t_id ||
                        formatMetaData(detail?.pdon_meta)?.card?.terminal_id ||
                        "---"}
                    </span>
                  </div>
                </div>
                {/* label value box end */}
                {/* label value box start */}
                {/* <div className="label-value-box border-theme-bottom">
                  <p className="label">Cohort </p>
                  <div className="value">
                    <span>{detail?.cohort || "---"}</span>
                  </div>
                </div> */}
                {/* label value box end */}
                {/* label value box start */}
                <div className="label-value-box border-theme-bottom">
                  <p className="label">Cashier</p>
                  <div className="value">
                    <span>{detail?.cashier || "---"}</span>
                  </div>
                </div>
                {/* label value box end */}
              </div>
            </>
          )}
          {/* Device end */}
        </div>
        {/* content box end  */}
      </div>
    </RavenModal>
  );
};

export default TransactionDetailModal;
