import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
import { reactSelectStyle } from "../utils/helpers";
import ActionContext from "../context/ActionContext";
import "../styles/fragments/PerPageComponent.css"

interface MyComponentProps {
  className?: string;
}

const perPageList = [
  { label: "100", value: 100 },
  { label: "250", value: 250 },
  { label: "500", value: 500 },
  { label: "1000", value: 1000 },
  { label: "2000", value: 2000 },
  { label: "3000", value: 3000 },
];

const PerPageComponent: React.FC<MyComponentProps> = ({ className }) => {
  const actionCtx = useContext(ActionContext);
  return (
    // <div className={`per-page-wrapper-class-index ${className}`}>
      <RavenInputField
        type="select"
        color="deep-green-light"
        selectStyles={reactSelectStyle}
        value={actionCtx?.perPageGlobalVal}
        onChange={(e: any) => {
          actionCtx.setPerPageGlobalVal(e);
        }}
        selectOption={perPageList}
       className={`per-page-wrapper-class-index card-bg ${className}`}
      />
    // </div>
  );
};

export default PerPageComponent;
