import React, { useEffect, useState } from "react";
import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { RouteLinks } from "../../routes/RouteLinks";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { loginUserWithOtp } from "../../redux/auth";

interface MyComponentProps {
  onClose: () => void;
  showModal: boolean;
  onSucess: () => void;
  detail?: any;
  onResend?: () => void;
  loadingParent?: boolean;
}

const ConfirmOtpModal: React.FC<MyComponentProps> = ({
  onClose,
  showModal,
  onSucess,
  detail,
  onResend,
  loadingParent,
}) => {
  const [completeToken, setCompleteToken] = useState(false);
  const dispatch = useDispatch();
  const [toggleKey, setKeyToggle] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    token: "",
  });

  const handleSubmit = debounce(async (param: any) => {
    setLoading(true);
    const obj = {
      code: param || details?.token,
      email: detail?.email,
    };
    const data = await dispatch(loginUserWithOtp(obj as any) as any);
    if (data?.payload?.status === "fail") {
      setLoading(false);
    }
    if (data?.payload?.status === "success") {
      setLoading(false);
      const urlLink = window?.location.href.split("?")[1];
      if (urlLink) {
        navigate(`/${urlLink}`);
      } else {
        navigate(RouteLinks?.Overview);
      }
    }
  }, 50);

  useEffect(() => {
    setKeyToggle(!toggleKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleResendEmailOtp = async () => {};

  function setShowResend(arg0: boolean) {
    throw new Error("Function not implemented.");
  }

  return (
    <RavenModal
      onClose={onClose}
      visble={showModal}
      btnLabel="Verify Account"
      btnColor={`green-light`}
      effect={`fadeInUp`}
      disabled={!completeToken}
      loading={loading || loadingParent}
      onBtnClick={handleSubmit}
      className={`auth-pin-modal-wrap`}
    >
      <form action="" className="form form-modal-auth-login">
        <div className="text-box">
          <p className="big-title">Login Verification</p>
          <p className="small-title">
            Please kindly provide the 6-Digit OTP sent to the  email provided to
            Sign In to account.
          </p>
        </div>
        <RavenInputField
          type={`pin`}
          // label={`Enter token sent to your email`}
          color={`green-light`}
          onChange={(e: any) => {
            setCompleteToken(false);
            setDetails((prev) => {
              return { ...prev, token: e };
            });
          }}
          onComplete={(pin: any) => {
            setCompleteToken(true);
            handleSubmit(pin);
          }}
          value={details?.token}
          showCountDown
          // countDownTime={1000}
          key={toggleKey as any}
          onCountDownComplete={() => setShowResend(true)}
          countdownFinishClick={handleResendEmailOtp}
          countdownFinishText={` Resend OTP`}
          countdownFinishClassName={`resend-text`}
          // onCountdownFinish
          countdownLabelClassName={`black-white-color`}
        />
        {/* <p onClick={handleResendEmailOtp} className="resend-text">
          Resend OTP
        </p> */}
      </form>
    </RavenModal>
  );
};

export default ConfirmOtpModal;
