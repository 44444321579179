import React from "react";
import "../styles/fragments/ImageTextFragment.css";

interface MyComponentProps {
  textOne?: string;
  imgVal?: any;
  imgUrl?: string;
  textTwo?: string;
  imgTwo?: boolean;
}

const ImageTextFragment: React.FC<MyComponentProps> = ({
  textOne,
  imgVal,
  imgUrl,
  textTwo,
  imgTwo
}) => {
  return (
    <div
      className="img-text-table-wrap-box
    "
    >
      <div className={`img-wrap-box-trans ${imgVal && "img-wrap-box-trans-two"} grey-bg-two`}>
        <figure className={`img-box ${imgVal && "img-box-two"}`}>
          {!imgVal ? <img className="img"  src={imgUrl} alt="" /> : imgVal}
        </figure>
      </div>

     <div className="text-box">
        <p className="text-one">{textOne}</p>
        <p className="text-two grey-white-color">{textTwo}</p>
     </div>
    </div>
  );
};

export default ImageTextFragment;
