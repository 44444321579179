import { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitleTextBox from "../../../fragments/TitleTextBox";
import "../../../styles/dashboard/overview/OverviewIndex.css";
import ContainerWrapper from "../../../fragments/ContainerWrapper";
// import ActionContext from "../../../context/ActionContext";
import CardCollectionDetails from "./CardCollectionDetails";
import BankTransferDetails from "./BankTransferDetails";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import RootState from "../../../redux/types";
// import { perPageVal } from "../../../utils/helpers";
import {
  exportOverviewReport,
  getMerchantInfo,
  getOverview,
} from "../../../redux/overview";
import {
  formatNumWithCommaNaira,
  formatNumWithCommaNairaSymbol,
} from "../../../helper";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { formatNumWithCommaNairaSymbolTwo } from "../../../utils/helpers";
import ProccessSettlementModal from "./ProcessSettlementModal";

const OverviewIndex = () => {
  const selectList = ["Card Collections", "Bank Transfers"];
  const [selectedOption, setSelectedOption] = useState("Card Collections");
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPageVal, setCurrentPageVal] = useState(1);
  const actionCtx = useContext(ActionContext);
  //   const [chartData, setchartData] = useState<[]>([]);
  //   const [noListFound, setNoListFound] = useState(true);
  const {
    loading,
    overview,
    loading_reciept,
    settle_business_info,
    settlement_info,
  } = useSelector((state: RootState) => state.overview);
  type showModalProp = {
    settle: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    settle: false,
  });
  useEffect(() => {
    if (Object?.keys(actionCtx?.configDetails)?.length > 0) {
      getAllOverviewFunc();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageVal, actionCtx?.configDetails]);

  const getAllOverviewFunc = async () => {
    const obj = {
      //   perPage: perPageVal,
      //   currentPage: currentPageVal,
      //   filterType: selectedOption === "Card Collections" ? "card" : ""
    };
    dispatch(getOverview(obj as any) as any);
  };

  const handleExportOverview = async () => {
    const data = await dispatch(exportOverviewReport({} as any) as any);
    // console.log(data);
    if (data?.payload?.status === "success") {
      const url = data?.payload?.data?.data;
      downloadCsv(url);
    }
  };

  const downloadCsv = (url: any) => {
    window?.open(url, "_blank")?.focus();
  };

  useEffect(() => {
    if (
      Object?.keys(settle_business_info)?.length < 1 ||
      Object?.keys(settlement_info)?.length < 1
    ) {
      dispatch(getMerchantInfo({} as any) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.configDetails]);

  const arrowIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12"
        stroke="#002856"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitleTextBox
          text="Manage Bankbox devices on the platform."
          title="Overview"
        >
          <RavenButton
            loading={loading_reciept}
            color="deep-green-light"
            label={`Generate Statement`}
            style={{ marginLeft: "auto" }}
            onClick={handleExportOverview}
          />
        </TitleTextBox>
        {/* title box end */}
        {/* dasboard overview box starrt */}
        {loading && Object?.keys(overview)?.length < 1 ? (
          <ContainerWrapper
            width={`100%`}
            dontShowHeader
            noContent={true}
            noContentLoading={true}
            loading={loading}
          />
        ) : (
          <div
            // onClick={() => {
            //   console.log(settle_business_info);
            //   console.log(settlement_info);
            // }}
            className="dashboard-overview-index-box-wrap"
          >
            {/* top detail box start */}
            <div className="top-detail-box-wrap">
              {/* total detail card start */}
              {Object?.keys(settle_business_info)?.length > 0 ? (
                <div className="total-detail-card-box total-detail-card-box-two card-bg">
                  <p className="card-title grey-white-color-white">
                    Wallet Balance
                  </p>
                  <p className="card-volume">
                    {formatNumWithCommaNairaSymbolTwo(
                      settle_business_info?.wallet_balance,
                      {}
                    )}
                  </p>
                  <div
                    style={{ paddingLeft: "0rem" }}
                    className="card-amount-box card-amount-box-two border-theme-top"
                  >
                    {Object?.keys(settlement_info)?.length > 0 && (
                      <div
                        onClick={() => {
                          setShowModal((prev) => {
                            return { ...prev, settle: true };
                          });
                        }}
                        className="process-settlement-icon-box grey-bg"
                      >
                        <p className="text">Process Settlement</p>
                        <div className="img-wrap">
                          <figure className="img-box card-bg border-theme">
                            {arrowIcon}
                          </figure>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* total detail card end */}
              {/* total detail card start */}
              <div className="total-detail-card-box card-bg">
                <p className="card-title grey-white-color-white">
                  Total Transactions
                </p>
                <p className="card-volume">
                  {formatNumWithCommaNaira(
                    String(overview?.upperSection?.total?.total_count || "0")
                  ) || "---"}
                </p>
                <div className="card-amount-box border-theme-top">
                  <p className="card-amount grey-white-color-white">
                    {formatNumWithCommaNairaSymbol(
                      overview?.upperSection?.total?.total_volume
                    )}
                  </p>
                </div>
              </div>
              {/* total detail card end */}
              {/* total detail card start */}
              <div className="total-detail-card-box card-bg">
                <p className="card-title grey-white-color-white">
                  Total Card Collection
                </p>
                <p className="card-volume">
                  {" "}
                  {formatNumWithCommaNairaSymbolTwo(
                    overview?.upperSection?.card?.total_count,
                    { hideDecimal: true, hideSymbol: true }
                  ) || "---"}
                </p>
                <div className="card-amount-box border-theme-top">
                  <p className="card-amount grey-white-color-white">
                    {formatNumWithCommaNairaSymbolTwo(
                      overview?.upperSection?.card?.total_volume
                    )}
                  </p>
                </div>
              </div>
              {/* total detail card end */}
              {/* total detail card start */}
              <div className="total-detail-card-box card-bg">
                <p className="card-title grey-white-color-white">
                  Total Bank Transfers
                </p>
                <p className="card-volume">
                  {" "}
                  {formatNumWithCommaNaira(
                    String(
                      overview?.upperSection?.collection?.total_count || "0"
                    )
                  ) || "---"}
                </p>
                <div className="card-amount-box border-theme-top">
                  <p className="card-amount grey-white-color-white">
                    {formatNumWithCommaNairaSymbol(
                      overview?.upperSection?.collection?.total_volume
                    ) || "---"}
                  </p>
                </div>
              </div>
              {/* total detail card end */}
              {/* total detail card start */}
              <div className="total-detail-card-box card-bg">
                <p className="card-title grey-white-color-white">
                  Total Terminals
                </p>
                <p className="card-volume">
                  {" "}
                  {formatNumWithCommaNaira(
                    String(overview?.upperSection?.terminalCount || "0")
                  ) || "---"}
                </p>
                <div className="card-amount-box border-theme-top">
                  <p className="card-amount grey-white-color-white">
                    {`Terminal Pending: ${
                      overview?.upperSection?.terminalPendingCount || "0"
                    }`}
                  </p>
                </div>
              </div>
              {/* total detail card end */}
            </div>
            {/* top detail box end */}
            {/* bottom box start */}
            <ContainerWrapper
              className="container-wrapper"
              dontShowHeader
              width={`100%`}
            >
              {/* select option box start */}
              <div className="select-option-box border-theme-bottom">
                {selectList?.map((chi, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`selected-item ${
                        selectedOption === chi && "selected-item-active"
                      } `}
                      onClick={() => {
                        if (selectedOption !== chi) {
                          setSelectedOption(chi);
                        }
                      }}
                    >
                      <span>{chi}</span>
                    </div>
                  );
                })}
              </div>
              {/* select option box end */}
              {/* bottom detail contenty start */}
              <div className="bottom-detail-content-wrap border-theme">
                {/* card type start */}
                {selectedOption === "Card Collections" && (
                  <CardCollectionDetails />
                )}
                {/* card type end */}
                {/* card type start */}
                {selectedOption === "Bank Transfers" && <BankTransferDetails />}
                {/* card type end */}
              </div>
              {/* bottom detail contenty end */}
            </ContainerWrapper>
            {/* bottom box end */}
          </div>
        )}

        {/* dasboard overview box end */}
      </DashboardLayout>
      <ProccessSettlementModal
        visible={showModal?.settle}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, settle: false };
          });
        }}
        onFinish={() => {
          setShowModal((prev) => {
            return { ...prev, settle: false };
          });
        }}
      />
    </>
  );
};

export default OverviewIndex;
