import { createContext, useState } from "react";

interface ActionContextInterface {
  themeChange: boolean;
  setThemeChnage: (param?: any) => void;
  logoutModal: boolean;
  setLogoutModal: (param?: any) => void;
  sessionExpire: boolean;
  setSessionExpire: (param?: any) => void;
  configDetails: any;
  setConfigDetails: (param?: any) => void;
  isSideNavMobile: boolean;
  setSideNavMobile: (param?: any) => void;
  noConfigFound: boolean;
  setNoConfigFound: (param?: any) => void;
  perPageGlobalVal: any;
  setPerPageGlobalVal: (param?: any) => void;
}

const ActionContext = createContext<ActionContextInterface>({
  themeChange: false,
  setThemeChnage: (param) => {},
  logoutModal: false,
  setLogoutModal: (param) => {},
  sessionExpire: false,
  setSessionExpire: (param) => {},
  configDetails: {},
  setConfigDetails: (param) => {},
  isSideNavMobile: false,
  setSideNavMobile: (param) => {},
  noConfigFound: true,
  setNoConfigFound: (param) => {},
  perPageGlobalVal: {},
  setPerPageGlobalVal: (param?: any) => {},
});

export function ActionContextProvider(props: any) {
  const [isThemeChange, setThemeChangeVal] = useState(false);
  const [isLogoutVal, setIslogoutVal] = useState(false);
  const [isSessionExpireVal, setSessionExpireVal] = useState(false);
  const [configDetailsVal, setConfigDetailsVal] = useState({});
  const [sideNavMobileVal, setSideNavMobileVal] = useState(false);
  const [noConfigFoundVal, setNoConfigFoundVal] = useState(true);
  const [perPageValue, setPerPageValue] = useState<any>({label: "250", value: 250});

  function setThemeChangeFunc(param: any) {
    setThemeChangeVal(param);
  }

  function logoutModalChange(param: any) {
    setIslogoutVal(param);
  }

  function SessionExpirefunc(param: any) {
    setSessionExpireVal(param);
  }

  function setConfigDetailFunc(param: any) {
    setConfigDetailsVal(param);
    //  console.log(param);

    document.documentElement.style.setProperty(
      "--primary-base-color",
      `#${param?.partnerInfo?.color_code}` as string
    );
  }

  function sideNavMobileFunction(param: any) {
    setSideNavMobileVal(param);
  }

  function noConfigFoundFunction(param: any) {
    setNoConfigFoundVal(param);
    // console.log(param);

  }

  function setPerPageValueFunc (param: any) {
    setPerPageValue(param)
  }

  const context = {
    themeChange: isThemeChange,
    setThemeChnage: setThemeChangeFunc,
    logoutModal: isLogoutVal,
    setLogoutModal: logoutModalChange,
    sessionExpire: isSessionExpireVal,
    setSessionExpire: SessionExpirefunc,
    configDetails: configDetailsVal,
    setConfigDetails: setConfigDetailFunc,
    isSideNavMobile: sideNavMobileVal,
    setSideNavMobile: sideNavMobileFunction,
    noConfigFound: noConfigFoundVal,
    setNoConfigFound: noConfigFoundFunction,
    perPageGlobalVal: perPageValue,
    setPerPageGlobalVal: setPerPageValueFunc,
  };

  return (
    <ActionContext.Provider value={context}>
      {props.children}
    </ActionContext.Provider>
  );
}

export default ActionContext;
