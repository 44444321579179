import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitleTextBox from "../../../fragments/TitleTextBox";
import ContainerWrapper from "../../../fragments/ContainerWrapper";
import "../../../styles/dashboard/transactions/TransactionIndex.css";
import visaIcon from "../../../assets/visa-icon.svg";
import verveIcon from "../../../assets/verve-icon.svg";
import masterIcon from "../../../assets/master-icon.svg";
import {
  RavenInputField,
  RavenTable,
  RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import FilterFragment from "../../../fragments/FilterFragment";
import ExportFragment from "../../../fragments/ExportFragment";
import PaginationFragment from "../../../fragments/PaginationFragment";
import BadgeComponent from "../../../fragments/badge/BadgeComponent";
import {
  detectCardType,
  maskCardNumber,
  // actionCtx?.perPageGlobalVal?.value,
} from "../../../utils/helpers";
import { getInsightTerminalTransactions } from "../../../redux/insight";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/types";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  // formatTypeFunction,
  lowerCaseWrap,
  trimLongString,
} from "../../../helper";
import { debounce } from "lodash";
import TransactionDetailModal from "../modal/TransactionDetailModal";
import ExportModal from "../modal/ExportModal";
import ActionContext from "../../../context/ActionContext";
import MobileTableCard from "../../../fragments/MobileTableWrap";
import TwoDetailView from "../../../fragments/TwoDetailView";
import ImageTextFragment from "../../../fragments/ImageTextFragment";
import PerPageComponent from "../../../fragments/PerPageComponent";
import DateFragmentBox from "../../../fragments/DateFragmentBox";

const TransactionIndex = () => {
  const dispatch = useDispatch();
  const [currentPageVal, setCurrentPageVal] = useState(1);
  const [search, setSearch] = useState("");
  const actionCtx = useContext(ActionContext);
  const { loading, insight_all_terminal_trx } = useSelector(
    (state: RootState) => state.insights
  );
  type showModalProp = {
    view: boolean;
    export?: boolean;
  };
  const [showModal, setShowModal] = useState<showModalProp>({
    view: false,
    export: false,
  });
  const [singleObj, setsingleObj] = useState({});
  const tableList = [
    "DETAILS",
    "REFERENCE",
    "TERMINAL DETAILS",
    "TYPE",
    "AMOUNT",
    "STATUS",
    "TRANSACTION DATE",
  ];

  useEffect(() => {
    if (Object?.keys(actionCtx?.configDetails)?.length > 0) {
      getTerminalDetailsTrans();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageVal, actionCtx?.configDetails, actionCtx.perPageGlobalVal]);

  const [noListFound, setNoListFound] = useState(true);
  const getTerminalDetailsTrans = async () => {
    const obj = {
      perPage: actionCtx?.perPageGlobalVal?.value,
      currentPage: currentPageVal,
    };
    const data = await dispatch(
      getInsightTerminalTransactions(obj as any) as any
    );
    if (data?.payload?.status === "success") {
      if (data?.payload?.data?.data?.transactions?.length > 0) {
        setNoListFound(false);
      }
    }
  };

  const handleSearch = debounce((param: any) => {
    const obj = {
      search: param,
      perPage: actionCtx?.perPageGlobalVal?.value,
    };
    if (param?.length > 0) {
      dispatch(getInsightTerminalTransactions(obj as any) as any);
    } else {
      getTerminalDetailsTrans();
    }
  }, 500);

  const bankDefaultImg = (
    <svg
      className="img"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 4.49952V8.49952M4.75 4.49952V8.49952M7.25 4.49952V8.49952M9.5 4.49952V8.49952M1.5 9.29952L1.5 9.69952C1.5 9.97955 1.5 10.1196 1.5545 10.2265C1.60243 10.3206 1.67892 10.3971 1.773 10.445C1.87996 10.4995 2.01997 10.4995 2.3 10.4995H9.7C9.98003 10.4995 10.12 10.4995 10.227 10.445C10.3211 10.3971 10.3976 10.3206 10.4455 10.2265C10.5 10.1196 10.5 9.97955 10.5 9.69952V9.29952C10.5 9.0195 10.5 8.87948 10.4455 8.77253C10.3976 8.67845 10.3211 8.60196 10.227 8.55402C10.12 8.49952 9.98003 8.49952 9.7 8.49952H2.3C2.01997 8.49952 1.87996 8.49952 1.773 8.55402C1.67892 8.60196 1.60243 8.67845 1.5545 8.77253C1.5 8.87948 1.5 9.0195 1.5 9.29952ZM5.82646 1.53809L2.12646 2.36031C1.90293 2.40998 1.79116 2.43482 1.70773 2.49492C1.63415 2.54794 1.57636 2.61997 1.54057 2.7033C1.5 2.79778 1.5 2.91228 1.5 3.14126L1.5 3.69952C1.5 3.97955 1.5 4.11956 1.5545 4.22652C1.60243 4.3206 1.67892 4.39709 1.773 4.44502C1.87996 4.49952 2.01997 4.49952 2.3 4.49952H9.7C9.98003 4.49952 10.12 4.49952 10.227 4.44502C10.3211 4.39709 10.3976 4.3206 10.4455 4.22652C10.5 4.11956 10.5 3.97955 10.5 3.69952V3.14126C10.5 2.91228 10.5 2.79778 10.4594 2.70331C10.4236 2.61997 10.3659 2.54794 10.2923 2.49492C10.2088 2.43482 10.0971 2.40998 9.87354 2.36031L6.17354 1.53809C6.10878 1.5237 6.0764 1.5165 6.04368 1.51363C6.01461 1.51108 5.98538 1.51108 5.95632 1.51363C5.9236 1.5165 5.89122 1.5237 5.82646 1.53809Z"
        stroke="#0B8376"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const getImageFunc = async (param: any) => {
    // console.log(param, "---------");
    if (param) {
      const img =
        await `https://businessapi.getraventest.com/static/media/banks/${param}.png`;
      // console.log(img);

      return img ? img : "";
    }
  };

  const getImageCardFunc = (param: any) => {
    let val;
    if (param) {
      const checkText =
        detectCardType(param) === "Visa"
          ? visaIcon
          : detectCardType(param) === "Master"
          ? masterIcon
          : verveIcon;
      val = checkText;
    }
    return val;
  };

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitleTextBox
          text="Manage Bankbox devices on the platform."
          title="All Transactions"
        />
        {/* title box end */}
        {/* contianer starrt */}
        <ContainerWrapper
          dontShowHeader
          noContent={
            Object?.keys(insight_all_terminal_trx)?.length < 1 && noListFound
              ? true
              : false
          }
          noContentText={`There are no transactions currently. Transactions \n made get to show up here.`}
          noContentTitle="No Transactions for now"
          noContentLoading={loading}
          // loading={true}
          width={`100%`}
        >
          {/* transaction-dashboard index wrap start */}
          <div
            // onClick={() => {
            //   console.log(insight_all_terminal_trx);
            // }}
            className="transaction-dashboard-index-wrap"
          >
            {/* serch export filter box start */}
            <div className="search-export-filter-box">
              {/* search box start */}
              <form
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="search-box"
                autoComplete="off"
              >
                <RavenInputField
                  color="green-light"
                  type="search"
                  placeholder="Search Transactions"
                  value={search}
                  onChange={(e: any) => {
                    handleSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                />
              </form>
              {/* search box end */}
              {/* filter-export pagination box start */}
              <div className="filter-export-pagination-box">
                <FilterFragment
                  filters={[
                    {
                      label: "Status",
                      filterBy: "status",
                      options: [
                        {
                          label: "Successful",
                          value: "success",
                        },
                        {
                          label: "Failed",
                          value: "failed",
                        },
                        {
                          label: "Reset",
                          value: "reset",
                        },
                      ],
                    },
                  ]}
                  page="all-transactions"
                />
                <ExportFragment
                  onClick={() => {
                    setShowModal((prev) => {
                      return { ...prev, export: true };
                    });
                  }}
                />
                <PaginationFragment
                  from={
                    insight_all_terminal_trx?.pagination?.currentPage || "1"
                  }
                  total={insight_all_terminal_trx?.pagination?.total || "1"}
                  perPageVal={actionCtx?.perPageGlobalVal?.value || "1"}
                  next={insight_all_terminal_trx?.pagination?.nextPage}
                  prev={insight_all_terminal_trx?.pagination?.prevPage}
                  onPrev={() => {
                    setCurrentPageVal(
                      insight_all_terminal_trx?.pagination?.prevPage
                    );
                  }}
                  onNext={() => {
                    // console.log(insight_all_terminal_trx?.pagination?.nextPage);

                    setCurrentPageVal(
                      insight_all_terminal_trx?.pagination?.nextPage
                    );
                  }}
                />
                <PerPageComponent />
              </div>
              {/* filter-export pagination box end */}
            </div>
            {/* serch export filter box end */}
            {/* table content start */}
            <div className="table-content-box">
              {/* big table start  */}
              <div className="big-table">
                <RavenTable headerList={tableList}>
                  {insight_all_terminal_trx?.transactions?.map(
                    (chi: any, idx: any) => {
                      return (
                        <RavenTableRow
                          loading={loading}
                          key={idx}
                          one={
                            (
                              <ImageTextFragment
                                imgUrl={
                                  chi?.type === "pdon_card" && chi?.meta_data
                                    ? getImageCardFunc(
                                        formatMetaData(chi?.meta_data)?.card_pan
                                      )
                                    : chi?.type === "pdon_card" &&
                                      !chi?.meta_data
                                    ? getImageCardFunc(
                                        formatMetaData(chi?.pdon_meta)?.card
                                          ?.card_pan
                                      )
                                    : chi?.type === "transfer_in"
                                    ? getImageFunc(
                                        formatMetaData(chi?.meta_data)?.source
                                          ?.bank_code
                                      )
                                    : chi?.type === "transfer_out"
                                    ? getImageFunc(
                                        formatMetaData(chi?.meta_data)
                                          ?.bank_code
                                      )
                                    : ("" as any)
                                }
                                imgVal={
                                  chi?.type === "transfer_in"
                                    ? bankDefaultImg
                                    : chi?.type === "transfer_out"
                                    ? bankDefaultImg
                                    : ""
                                }
                                textOne={
                                  chi?.type === "pdon_card" && chi?.meta_data
                                    ? maskCardNumber(
                                        formatMetaData(chi?.meta_data)?.card_pan
                                      )
                                    : chi?.type === "pdon_card" &&
                                      !chi?.meta_data
                                    ? maskCardNumber(
                                        formatMetaData(chi?.pdon_meta)?.card
                                          ?.card_pan
                                      )
                                    : chi?.type === "transfer_in"
                                    ? `${
                                        formatMetaData(chi?.meta_data)?.source
                                          ?.first_name || ""
                                      } ${
                                        formatMetaData(chi?.meta_data)?.source
                                          ?.last_name || ""
                                      }`
                                    : chi?.type === "transfer_out"
                                    ? formatMetaData(chi?.meta_data)
                                        ?.account_number
                                    : ""
                                }
                                textTwo={
                                  chi?.type === "pdon_card"
                                    ? `${detectCardType(
                                        formatMetaData(chi?.meta_data)?.card_pan
                                      )} Card`
                                    : chi?.type === "transfer_in"
                                    ? `${
                                        formatMetaData(chi?.meta_data)?.source
                                          ?.account_number
                                      } • ${
                                        formatMetaData(chi?.meta_data)?.source
                                          ?.bank
                                      }`
                                    : chi?.type === "transfer_out"
                                    ? formatMetaData(chi?.meta_data)?.bank
                                    : ""
                                }
                              />
                            ) || "---"
                          }
                          // two={
                          //   lowerCaseWrap(
                          //     formatMetaData(chi?.pdon_meta)
                          //       ?.terminal_profile?.poseidon_label
                          //   ) || "---"
                          // }
                          two={
                            (
                              <TwoDetailView
                                title={
                                  chi?.type !== "transfer_in"
                                    ? chi?.rrn
                                    : `Session ID`
                                }
                                copy={
                                  chi?.type === "transfer_in"
                                    ? formatMetaData(chi?.meta_data)?.session_id
                                    : ""
                                }
                                text_one={
                                  chi?.type !== "transfer_in"
                                    ? `Stan: ${chi?.stan}`
                                    : `${trimLongString(
                                        formatMetaData(chi?.meta_data)
                                          ?.session_id,
                                        15
                                      )}`
                                }
                                // text_two={chi?.stan || "--"}
                              />
                            ) || "---"
                          }
                          three={
                            (
                              <TwoDetailView
                                text_one={`Label : ${
                                  chi?.type !== "transfer_in"
                                    ? lowerCaseWrap(
                                        formatMetaData(chi?.pdon_meta)
                                          ?.terminal_profile?.poseidon_label
                                      )
                                    : lowerCaseWrap(
                                        formatMetaData(chi?.pdon_meta)?.terminal
                                          ?.tid_label || ""
                                      )
                                }`}
                                title={
                                  chi?.type !== "transfer_in"
                                    ? formatMetaData(chi?.pdon_meta)
                                        ?.terminal_profile?.poseidon_tid
                                    : chi?.t_id
                                }
                                // text_two={chi?.stan}
                              />
                            ) || "---"
                          }
                          four={
                            chi?.type === "transfer_out"
                              ? "Transfer out"
                              : chi?.type === "transfer_in"
                              ? "Transfer in"
                              : "Card"
                          }
                          five={
                            formatNumWithCommaNairaSymbol(chi?.amount) || "---"
                          }
                          onRowClick={() => {
                            // console.log(chi);
                            // console.log(formatMetaData(chi?.pdon_meta));
                            // console.log(formatMetaData(chi?.meta_data));

                            setsingleObj(chi);
                            setShowModal((prev) => {
                              return { ...prev, view: true };
                            });
                          }}
                          six={
                            <BadgeComponent
                              text={
                                String(chi?.status) === "00"
                                  ? `Success`
                                  : `Failed`
                              }
                              className={
                                String(chi?.status) === "00"
                                  ? `success`
                                  : `failed`
                              }
                            />
                          }
                          // six={
                          //   <BadgeComponent
                          //     text={
                          //       String(
                          //         formatMetaData(chi?.pdon_meta)?.card
                          //           ?.response_code
                          //       ) === "00"
                          //         ? `Success`
                          //         : `Failed`
                          //     }
                          //     className={
                          //       String(
                          //         formatMetaData(chi?.pdon_meta)?.card
                          //           ?.response_code
                          //       ) === "00"
                          //         ? `success`
                          //         : `failed`
                          //     }
                          //   />
                          // }
                          seven={
                            <DateFragmentBox date={chi?.created_at}/>
                            // chi?.created_at
                            //   ? formatDate(chi?.created_at)?.replace("—", "•")
                            //   : "---"
                          }
                        />
                      );
                    }
                  )}
                </RavenTable>
              </div>
              {/* big table end */}
              {/* mobile wrap table start */}
              <div className="mobile-table-box">
                {insight_all_terminal_trx?.transactions?.map(
                  (chi: any, idx: any) => {
                    return (
                      <MobileTableCard
                        amountColor={
                          chi?.direction === "credit"
                            ? "#1ACE37"
                            : chi?.direction === "debit"
                            ? `#FF0F00`
                            : "#1ACE37"
                        }
                        text={
                          chi?.type === "transfer_out"
                            ? "Transfer out"
                            : chi?.type === "transfer_in"
                            ? "Transfer in"
                            : "Card"
                        }
                        title={
                          chi?.type === "pdon_card" && chi?.meta_data
                            ? maskCardNumber(
                                formatMetaData(chi?.meta_data)?.card_pan
                              )
                            : chi?.type === "pdon_card" && !chi?.meta_data
                            ? maskCardNumber(
                                formatMetaData(chi?.pdon_meta)?.card?.card_pan
                              )
                            : chi?.type === "transfer_in"
                            ? `${
                                formatMetaData(chi?.meta_data)?.source
                                  ?.first_name || ""
                              } ${
                                formatMetaData(chi?.meta_data)?.source
                                  ?.last_name || ""
                              }`
                            : chi?.type === "transfer_out"
                            ? formatMetaData(chi?.meta_data)?.account_number
                            : chi?.reference || "---"
                        }
                        amount={
                          formatNumWithCommaNairaSymbol(chi?.amount) || "---"
                        }
                        textTwo={
                          chi?.type === "pdon_card"
                            ? `${detectCardType(
                                formatMetaData(chi?.meta_data)?.card_pan
                              )} Card`
                            : chi?.type === "transfer_in"
                            ? `${
                                formatMetaData(chi?.meta_data)?.source
                                  ?.account_number
                              } • ${
                                formatMetaData(chi?.meta_data)?.source?.bank
                              }`
                            : chi?.type === "transfer_out"
                            ? formatMetaData(chi?.meta_data)?.bank
                            : ""
                        }
                        smallText={
                          <DateFragmentBox date={chi?.created_at}/> as any
                          // chi?.created_at
                          //   ? formatDate(chi?.created_at)?.replace("—", "•")
                          //   : "---"
                        }
                        // smallText={
                        //   (
                        //     <BadgeComponent
                        //       text={
                        //         String(
                        //           formatMetaData(chi?.pdon_meta)?.card
                        //             ?.response_code
                        //         ) === "00"
                        //           ? `Success`
                        //           : `Failed`
                        //       }
                        //       className={
                        //         String(
                        //           formatMetaData(chi?.pdon_meta)?.card
                        //             ?.response_code
                        //         ) === "00"
                        //           ? `success`
                        //           : `failed`
                        //       }
                        //     />
                        //   ) as any
                        // }
                        key={idx}
                        //   smallText={ref}
                        showImg
                        type={chi?.direction || " "}
                        onRowClick={() => {
                          setsingleObj(chi);
                          setShowModal((prev) => {
                            return { ...prev, view: true };
                          });
                        }}
                      />
                    );
                  }
                )}
              </div>
              {/* mobile wrrap table end */}
            </div>
            {/* table content end */}
          </div>
          {/* transaction-dashboard index wrap end */}
        </ContainerWrapper>
        {/* contianer end */}
      </DashboardLayout>
      {/* modal start */}
      <TransactionDetailModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        detail={singleObj}
        visible={showModal?.view}
        // visible={showModal?.view || true}
      />
      <ExportModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, export: false };
          });
        }}
        visible={showModal?.export}
        // visible={showModal?.view || true}
      />
      {/* modal end */}
    </>
  );
};

export default TransactionIndex;
